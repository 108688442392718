import React, { useContext, useEffect } from 'react';
import { SubconContext } from './SubconContext';
import { IControllerConstantProps } from 'containers/SubcontractModule/ConSubconHelper/ConSubconInterface';
import { RateUomType, useDocumentListingLazyQuery } from 'generated/graphql';
import useUploadAttachment from '@ifca-root/react-component/src/utils/hooks/useUploadAttachment';
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents';
import NumberFormatController from 'containers/SubcontractModule/ConSubconHelper/NumberFormatController';
import DropdownController from 'containers/SubcontractModule/ConSubconHelper/DropDownController';
import { FileUploadInput } from '@ifca-root/react-component/src/components/Input/FileUploadInput';
import { UploadPreview } from '@ifca-root/react-component/src/components/Avatar/UploadPreview';

export const SubconLAD = () => {
  const {
    mode,
    // conSubcon,
    control,
    handleSubmit,
    errors,
    register,
    watch,
    setLADFiles,
    disableCondition,
    contractData,
    subcontractData,
    subcontractID,
    editType,
  } = useContext(SubconContext);

  const contConst: IControllerConstantProps = {
    control: control,
    register: register,
  };

  const editData = mode !== 'add' ? subcontractData[0] : null;

  const LADRateConstant = Object.values(RateUomType);
  const LADRateConstantRecon = LADRateConstant.map(x => {
    return {
      value: x,
      name:
        x === RateUomType.Daily
          ? 'Daily'
          : x === RateUomType.Hourly
          ? 'Hourly'
          : x === RateUomType.Lumpsum
          ? 'Lumpsum'
          : x === RateUomType.Monthly
          ? 'Monthly'
          : null,
    };
  });

  const {
    // handleEditUploadMap,
    // fileMap,
    // handleUploadMapping,
    // previewMap,
    // removeMappedFiles,
    files,
    handleUploadChange,
    previewFiles,
    remove: removeFile,
    handleEditUpload,
  } = useUploadAttachment();

  useEffect(() => {
    if (!!files) setLADFiles(files);
  }, [files]);

  const [
    documents,
    {
      data: { DocumentListing } = { DocumentListing: [] },
      loading: documentLoading,
      error: documentError,
    },
  ] = useDocumentListingLazyQuery({
    fetchPolicy: 'network-only',
    variables: {
      refID: subcontractID,
      description: 'LAD',
    },
    onCompleted: ({ DocumentListing }) => {
      handleEditUpload(DocumentListing);
      // previewFiles = [];
      previewFiles.push(...DocumentListing?.map(v => v.fileURL));
    },
  });

  useEffect(() => {
    if (mode !== 'add' && editType === 'lad') documents();
  }, [mode, editType]);

  return (
    <>
      <CardContents
        section={{
          header: {
            title: 'Liquidated and Ascertained Damages (LAD)',
          },
        }}
      >
        <NumberFormatController
          {...contConst}
          name="LADAmt"
          label="LAD Amount"
          decimalScale={2}
          multiline={true}
          className="left"
          disabled={disableCondition}
          defaultValue={editData?.ladAmt || 0}
        />
        <DropdownController
          {...contConst}
          name={'LADRate'}
          label="Select LAD Rate"
          fullWidth={true}
          dropDownData={LADRateConstantRecon}
          dropDownShowMenu={'name'}
          dropDownValue={'value'}
          className="right"
          dropDownShowValue={() =>
            LADRateConstantRecon?.find(v => v?.value === watch('LADRate'))
              ?.value
          }
          disabled={disableCondition}
          defaultValue={editData?.ladRate}
        />

        <FileUploadInput
          label="Attachment"
          files={files}
          onHandleUploadChange={handleUploadChange}
          multiple
          // mapData={'LAD'}
          imagePreview={
            <>
              {previewFiles?.map((v, i) => (
                <UploadPreview
                  remove
                  key={v}
                  src={v}
                  onClick={() => removeFile(i)}
                  mediaType={
                    files?.length > 0
                      ? files[i]?.type
                      : DocumentListing[i]?.mediaType
                  }
                />
              ))}
            </>
          }
        />
      </CardContents>
    </>
  );
};
