import React, { useContext } from 'react';
import { SubconContext } from './SubconContext';
import { numberReturnValue } from 'containers/SubcontractModule/ConSubconHelper/ConSubconInput';
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents';
import NumberFormatController from 'containers/SubcontractModule/ConSubconHelper/NumberFormatController';
import NumberFormat from 'react-number-format';
import { TextField } from '@material-ui/core';
import { amtStr } from '@contract-root/admin-react/src/helpers/StringNumberFunction/numFormatter';

export const SubconRetention = () => {
  const {
    watch,
    contConst,
    // conSubcon,
    disableCondition,
    mode,
    contractData,
    subcontractData,
    errors,
    setValue,
  } = useContext(SubconContext);

  const editData = mode === 'edit' ? subcontractData[0] : null;

  const projectSum =
    mode === 'add' || watch('subcontractSum')
      ? numberReturnValue(watch('subcontractSum'))
      : subcontractData[0]?.revisedSubcontractSum ||
        subcontractData[0]?.subcontractSum;

  const calculateMaxRet = () => {
    if (watch('maxRetentionPerc')) {
      return (projectSum * numberReturnValue(watch('maxRetentionPerc'))) / 100;
    } else if (!watch('maxRetentionPerc') && mode === 'edit') {
      return (projectSum * editData?.maxRetentionPerc) / 100;
    } else return 0;
  };

  const watchForRetention = () => {
    if (
      watch('progressRetentionPerc') === '' ||
      parseFloat(watch('progressRetentionPerc')) === 0
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <CardContents
        section={{
          header: {
            title: 'Retention Details',
          },
        }}
      >
        <NumberFormatController
          {...contConst}
          name="progressRetentionPerc"
          label="Progress Retention (%) *"
          decimalScale={2}
          multiline={true}
          disabled={disableCondition}
          onChange={val => {
            if (val?.value === '' || val?.value === '0.00') {
              setValue('maxRetentionPerc', 0.0);
            } else {
              if (
                parseFloat(watch(`maxRetentionPerc`)) === 5.0 ||
                parseFloat(watch(`maxRetentionPerc`)) === 0.0
              ) {
                setValue('maxRetentionPerc', 5.0);
              }
            }
          }}
          defaultValue={editData?.progressRetentionPerc ?? 10}
          errors={errors}
        />
        <NumberFormatController
          {...contConst}
          name="maxRetentionPerc"
          label="Max Retention (%) *"
          decimalScale={2}
          multiline={true}
          className="left"
          disabled={disableCondition || watchForRetention()}
          errors={errors}
          defaultValue={editData?.maxRetentionPerc ?? 5}
        />

        <NumberFormat
          customInput={TextField}
          allowNegative={false}
          name="retAmt"
          label="Max Retention Amt"
          className="outlined-disabled right"
          id="filled-basic"
          variant="outlined"
          margin="normal"
          disabled
          isNumericString
          // value={amtStr(
          //   (projectSum * numberReturnValue(watch('maxRetentionPerc'))) / 100 ||
          //     0.0,
          // )}
          value={amtStr(calculateMaxRet())}
        />
      </CardContents>
    </>
  );
};
