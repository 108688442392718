import React, { useState, useEffect, useContext } from 'react';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { useHistory, useLocation, useParams } from 'react-router';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { yupResolver } from '@hookform/resolvers';
import { UploadPreview } from '@ifca-root/react-component/src/components/Avatar/UploadPreview';
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents';
import { FileUploadInput } from '@ifca-root/react-component/src/components/Input/FileUploadInput';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import useUploadAttachment from '@ifca-root/react-component/src/utils/hooks/useUploadAttachment';
import { TextField, MenuItem } from '@material-ui/core';
import * as yup from 'yup';
import { Footer } from '@ifca-root/react-component/src/components/Footer/Footer';
import { CommonYupValidation } from '@contract-root/admin-react/src/helpers/yup';
import { Controller, useForm } from 'react-hook-form';
import { SystemMsgs } from '@contract-root/admin-react/src/helpers/Messages/SystemMsg';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import {
  CostClass,
  useGetCostCategoryQuery,
  useDocumentListingLazyQuery,
  useGetContractQuery,
  useGetSubcontractorQuery,
  useGetSiteLazyQuery,
  useGetConTaxSchemeQuery,
  useGetSubcontractorForSubcontractQuery,
  AssociatedStatus,
  useGetSubcontractLazyQuery,
  useGetWbsParentChildLazyQuery,
  ContractStatus,
  useCreateSubcontractMutation,
  useUpdateSubcontractMutation,
} from 'generated/graphql';
import NumberFormat from 'react-number-format';
import { amtStr } from '@contract-root/admin-react/src/helpers/StringNumberFunction/numFormatter';
import { ConfirmationDialog } from 'components/Dialog/ConfirmationDialog';
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader';
import {
  useAssociateCheckingLazyQuery,
  useGetContactLazyQuery,
  useGetSubcontractTradeCostCategoryLazyQuery,
} from '@contract-root/contract-react/src/generated/graphql';
import { formatDate } from '@contract-root/admin-react/src/helpers/StringNumberFunction/formatDate';
import { SubconContext } from './Hooks/SubcontractComponents/SubconContext';
import { SubconGeneralForm } from './Hooks/SubcontractComponents/SubconGeneralForm';
import {
  IConSubconProps,
  IControllerConstantProps,
} from './ConSubconHelper/ConSubconInterface';
import {
  yupCondition,
  yupObjectFinal,
} from './ConSubconHelper/ConSubconYupObject';
import {
  DocNumInput,
  GeneralConSubconInput,
  LADInput,
  RetentionInput,
  SubconTradeExceedInput,
  SubcontractInput,
  defaultVals,
  inputData,
  numberReturnValue,
} from './ConSubconHelper/ConSubconInput';
import { SubconTradePackage } from './Hooks/SubcontractComponents/SubconTradePackageModule/SubconTradePackage';
import { SubconTradeExceed } from './Hooks/SubcontractComponents/SubconTradeExceedModule/SubconTradeExceed';
import { smTitle } from 'helpers/GlobalVar/GlobalVariable';
import { SubconAddressList } from './Hooks/SubcontractComponents/SubconAddressModule/SubconAddressList';
import { SubconContactList } from './Hooks/SubcontractComponents/SubconContactModule/SubconContactList';
import { SubconRetention } from './Hooks/SubcontractComponents/SubconRetention';
import { SubconLAD } from './Hooks/SubcontractComponents/SubconLAD';
import { SubconDocNum } from './Hooks/SubcontractComponents/SubconDocNum';
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog';
import { Avatar, Grid, List, ListItem, ListItemText } from '@material-ui/core';
import { useSubconBudgetCheckingLazyQuery } from '@contract-root/admin-react/src/generated/graphql';
import SnackBarContext from 'containers/App/Store/SnackBarContext';

interface subconProps {
  contractID: string;
  contractNo: string;
  subcontractNo: string;
  contractDate?: Date;
  subcontractDate?: Date;
  title: string;
  description: string;
  startDate: Date;
  contractSum?: number;
  subcontractSum?: number;
  siteID?: string;
  endDate: Date;
  subcontractorID?: string;
  taxSchemeID?: string;
  creditTerm?: string;
  siteName: string;
  customerID: string;
  address: any;
  contacts: any;
  subconTrade?: any;
  nominatedSC: boolean;
  LADAmt: number;
  LADRate: string;
  progressRetentionPerc: number;
  maxRetentionPerc: number;
  submissionComment?: string;
}

export const SubcontractForm = (props: any) => {
  const { mode } = props;
  const user = JSON.parse(localStorage.getItem('loggedInUser'));
  let history = useHistory();
  const location = useLocation();
  const { subcontractID, editType }: any = useParams();
  const editData: any = location?.state;
  const [contractID, setContractID]: any = useState('');
  const [exitDialog, setExitDialog] = useState(false);
  // state for the amount
  const [Amount, setAmount] = useState(editData?.subcontractSum);
  const [taxPerc, setTaxPerc] = useState(0);
  const [totalAmt, setTotalAmt] = useState(0.0);
  const [conID, setConID] = useState(editData?.mainContractDetail?.ID);
  const [openExceedDialog, setOpenExceedDialog] = useState<boolean>(false);
  const [flagTrade, setFlagTrade] = useState<boolean>(false);
  const [openSubmissionDialog, setOpenSubmissionDialog] = useState<boolean>(
    false,
  );
  const [tradeArr, setTradeArr] = useState<any[]>([]);
  const [errorDia, setErrorDia] = useState<boolean>(false);
  const [errMsg, setErrMsg] = useState<string>('');
  const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
    SnackBarContext as any,
  );

  const [
    associateChecking,
    { loading: associateLoading, data: associateCheck, called: checkingCalled },
  ] = useAssociateCheckingLazyQuery({
    variables: { subcontractID: subcontractID },
  });

  const {
    loading: costCategoryLoading,
    error,
    data: { getCostCategory } = { getCostCategory: [] },
  } = useGetCostCategoryQuery({
    fetchPolicy: 'network-only',
    variables: {
      contractID: contractID,
      costClass: CostClass.Subcontract,
    },
  });

  const [
    fetchCostCategory,
    {
      called: costCategoryCalled,
      loading: tradeCostLoading,
      data: { getSubcontractTradeCostCategory } = {
        getSubcontractTradeCostCategory: [],
      },
    },
  ] = useGetSubcontractTradeCostCategoryLazyQuery({
    fetchPolicy: 'network-only',
  });

  // Querying for Site Address
  const [
    loadSite,
    { data: { getSite } = { getSite: [] } },
  ] = useGetSiteLazyQuery({
    fetchPolicy: 'network-only',
  });

  // Query for contacts
  const [
    loadContact,
    {
      loading: contactLoading,
      error: errorLoading,
      data: { getContact } = { getContact: [] },
    },
  ] = useGetContactLazyQuery({
    fetchPolicy: 'network-only',
  });

  // Query for contracts
  const {
    called: contractCalled,
    loading: contractLoading,
    data: { getContract } = { getContract: [] },
  } = useGetContractQuery({
    fetchPolicy: 'network-only',
    variables: {
      orderByAsc: 'title',
    },
  });

  // Query for subcontractor
  const {
    called: subcontractorCalled,
    loading: subcontractorLoading,
    data: { getSubcontractor } = { getSubcontractor: [] },
  } = useGetSubcontractorForSubcontractQuery({
    fetchPolicy: 'network-only',
    variables: {
      associatedStatus: AssociatedStatus.Active,
      orderByAsc: 'name',
    },
    onCompleted: ({ getSubcontractor }) => {
      if (getSubcontractor) {
        loadContact({
          variables: { associateID: getSubcontract[0]?.ID ?? subcontractID },
        });
      }
    },
  });

  // Query for subcontract
  const [
    loadSubcontractData,
    {
      called: conCalled,
      loading: subcontractLoading,
      error: conError,
      data: { getSubcontract } = { getSubcontract: [] },
      refetch: refetchContract,
    },
  ] = useGetSubcontractLazyQuery({
    fetchPolicy: 'network-only',
  });

  const [
    loadWBSParentChild,
    {
      called: wbsParentChildCalled,
      loading: wbsParentChildLoading,
      error: wbsParentChildError,
      data: { getWBSParentChild } = { getWBSParentChild: [] },
    },
  ] = useGetWbsParentChildLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: ({ getWBSParentChild }) => {
      // fetchCostCategory({
      //   variables: { wbsID: getWBSParentChild?.ID, budgetID: getBudget[0]?.ID },
      // });
    },
  });

  //mutation
  const [
    createSubcontract,
    { loading: mutationLoading, error: mutationError, called: createConCalled },
  ] = useCreateSubcontractMutation({
    onError: error => {
      console.log('ERROR', error);
      setErrMsg(error.message.substr(15, error.message.length - 1));
      setErrorDia(true);
    },
    onCompleted: ({ createSubcontract }) => {
      if (!!createSubcontract) {
        setSnackBarMsg(SystemMsgs.createNewRecord());
        setOpenSnackBar(true);
        history.push('/subcontract');
      }
    },
  });

  const [
    updateSubcontract,
    { loading: updateLoading, error: updateError },
  ] = useUpdateSubcontractMutation({
    onError: error => {
      console.log('ERROR', error);
      setErrMsg(error.message.substr(15, error.message.length - 1));
      setErrorDia(true);
    },
    onCompleted: ({ updateSubcontract }) => {
      if (updateSubcontract) {
        setSnackBarMsg(SystemMsgs.updateRecord());
        setOpenSnackBar(true);
        if (editType) {
          history.push(`/subcontract/submenu/${subcontractID}/detail`);
        } else {
          history.push(`/subcontract`);
        }
      }
    },
  });

  useEffect(() => {
    if (mode !== 'add') {
      associateChecking();
      loadSubcontractData({ variables: { ID: subcontractID } });
    }
  }, [mode]);

  const [
    selectedStartDate,
    setSelectedStartDate,
  ] = React.useState<Date | null>();
  const [selectedEndDate, setSelectedEndDate] = React.useState<Date | null>();

  const [contractAmt, setcontractAmt] = useState(0);

  useEffect(() => {
    setcontractAmt(
      getContract.filter(arr => arr.ID == editData?.mainContractDetail?.ID)[0]
        ?.contractSum,
    );
  }, [editData, getContract]);

  useEffect(() => {
    if (contractID) {
      loadWBSParentChild({
        variables: { contractID: contractID },
      });
    }
  }, [contractID]);

  const GeneralCondition = () => {
    const defCheck: boolean =
      !contractLoading && !subcontractorLoading && !costCategoryLoading;

    if (mode === 'add') return defCheck;
    else return defCheck && getSubcontract?.length > 0;
  };

  /// check for editType
  const cond = (edit_type: string) => {
    if (!editType) {
      return mode === 'add' || mode === 'edit' || mode === 'resubmit';
    } else {
      return (mode === 'add' || mode === 'edit') && editType === edit_type;
    }
  };

  //validation
  const validationSchema = yup.object().shape({
    maincon: CommonYupValidation.requireField(SystemMsgs.description()),
    subcontractPackage: CommonYupValidation.requireField(
      SystemMsgs.costCategory(),
    ),
    subConTitle: CommonYupValidation.requireField(SystemMsgs.title()),
    subcontract_date: CommonYupValidation.requireField(
      SystemMsgs.dateSelection(),
    ),
    subcontract_No: CommonYupValidation.requireField(
      SystemMsgs.subcontractNo(),
    ),
    taxSchemeID: CommonYupValidation.requireField(SystemMsgs.taxType()),
    description: CommonYupValidation.requireField(SystemMsgs.description()),
    start_date: CommonYupValidation.requireField(SystemMsgs.dateSelection()),
    end_date: CommonYupValidation.requireField(SystemMsgs.dateSelection()),
    creditTerm: CommonYupValidation.requireField(SystemMsgs.creditTerm()),
    SubcontractAmount: yup
      .number()
      .required('Require a proper amount')
      .max(contractAmt, 'Subcontract sum must be less than contract sum')
      .min(1, 'The amount should not be zero'),
  });

  useEffect(() => {
    if (editData) {
      loadSite({
        variables: { ID: editData?.site.ID },
      });
      loadDoc();
    }
  }, [editData]);

  // Use for attachment
  const {
    files,
    handleUploadChange,
    previewFiles,
    remove: removeFile,
    handleEditUpload,
  } = useUploadAttachment();

  const [
    loadDoc,
    { loading: DocLoad, error: DocError, data: DocData },
  ] = useDocumentListingLazyQuery({
    variables: { refID: editData?.ID },
    fetchPolicy: 'network-only',
    onCompleted: data => {
      handleEditUpload(data?.DocumentListing);
      previewFiles.push(...data?.DocumentListing?.map(x => x?.fileURL));
    },
  });

  const {
    data: { getConTaxScheme } = { getConTaxScheme: [] },
  } = useGetConTaxSchemeQuery({
    onCompleted: () => {},
  });

  const [
    subconBudgetFunction,
    {
      called: budgetCalled,
      loading: budgetLoading,
      error: budgetError,
      data: { subconBudgetChecking } = { subconBudgetChecking: {} },
    },
  ] = useSubconBudgetCheckingLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: ({ subconBudgetChecking }) => {
      if (
        !subconBudgetChecking?.isAllocated ||
        subconBudgetChecking?.isExceeded
      ) {
        setOpenTrade(true);
      } else {
        handleSubmit(data => onSubmit(data, mode, true))();
      }
    },
  });

  const calculateTotalAmt = () => {
    setTotalAmt(Amount + Amount * (taxPerc / 1000));
  };

  useEffect(() => {
    let latestTaxEffective = getConTaxScheme.filter(
      x => x?.ID === editData?.taxDetail?.ID,
    )[0];
    setTaxPerc(latestTaxEffective?.taxEffective[0]?.taxRate);
  }, [editData, getConTaxScheme]);

  useEffect(() => {
    calculateTotalAmt();
  }, [Amount, taxPerc]);

  const [dateData, setDateData] = useState<{
    startDate: string;
    endDate: string;
    projDate: string;
  }>({ startDate: '', endDate: '', projDate: '' });
  const [contractSum, setContractSum] = useState<number>(null);
  const [contractDate, setContractDate] = useState<any>(null);
  const [implementWbs, setImplementWbs] = useState<boolean>(false);
  const [infoFiles, setInfoFiles] = useState(null);
  const [LADFiles, setLADFiles] = useState(null);
  const [subconTradeFiles, setSubconTradeFiles] = useState<any>();
  const [openTradeDia, setOpenTrade] = useState<boolean>(false);
  const [contractStartEnd, setContractStartEnd] = useState<any>({
    conStart: null,
    conEnd: null,
  });

  const {
    control,
    register,
    handleSubmit,
    errors,
    watch,
    setValue,
    clearErrors,
    formState,
  } = useForm<IConSubconProps>({
    defaultValues: defaultVals(mode, 'subcontract', getSubcontract[0]),
    mode: 'onSubmit',
    resolver: yupCondition(mode, editType)
      ? yupResolver(
          yupObjectFinal(
            mode,
            editType,
            'subcontract',
            dateData,
            contractSum,
            contractDate,
            contractStartEnd,
            implementWbs,
          ),
        )
      : null,
  });

  const contConst: IControllerConstantProps = {
    control: control,
    register: register,
  };

  const onSubmit = (data, submitMode: string, proceed?: boolean) => {
    let totalTradeSum = 0;
    let subconStatus;

    const defaultProps = {
      mode: mode,
      data1: data,
      data2: getSubcontract[0],
    };

    const generalInput = inputData({
      func: GeneralConSubconInput,
      extraFiles: infoFiles,
      isCurrEditType: editType === 'info',
      ...defaultProps,
    });
    const subcontractInput = inputData({
      func: SubcontractInput,
      extraFiles: infoFiles,
      isCurrEditType: editType === 'info',
      ...defaultProps,
    });

    const ladInput = inputData({
      func: LADInput,
      extraFiles: LADFiles,
      isCurrEditType: editType === 'lad',
      ...defaultProps,
    });
    const retentionInput = inputData({
      func: RetentionInput,
      isCurrEditType: editType === 'retention',
      ...defaultProps,
    });

    const docNumInput = inputData({
      func: DocNumInput,
      isCurrEditType: editType === 'docNo',
      ...defaultProps,
    });

    const exceedTradeInput = inputData({
      func: SubconTradeExceedInput,
      extraFiles: subconTradeFiles,
      isCurrEditType: editType === 'subconTradeExceed',
      ...defaultProps,
    });

    const subconTradeInput = data?.subconTrade
      ? data?.subconTrade?.map(v => {
          return {
            ID: v?.ID,
            wbsID: v?.wbsID,
            costCategoryID: v?.costCategoryID,
            tradeAmt: parseFloat(v?.tradeAmt),
            taxSchemeID: v?.taxSchemeID,
          };
        })
      : getSubcontract[0]?.subcontractCostWbs?.map(v => {
          return {
            ID: v?.ID,
            wbsID: v?.wbsID,
            costCategoryID: v?.costCategoryID,
            tradeAmt: v?.tradeAmt,
            taxSchemeID: v?.taxSchemeID,
          };
        });

    const contactPersonInput = data?.contacts
      ? data?.contacts?.map(v => {
          return {
            ID: v?.ID,
            name: v?.name,
            email: v?.email,
            designation: v?.designation,
            contactNo: v?.contactNo,
            associateID: v?.associateID,
          };
        })
      : getSubcontract[0]?.contact?.map(x => {
          return {
            ID: x?.ID,
            name: x?.name,
            email: x?.email,
            designation: x?.designation,
            contactNo: x?.contactNo,
          };
        });
    // : getSubcontract[0]?.contactPerson;

    // if (submitMode === 'draft') {
    //   subconStatus = ContractStatus.Draft;
    // } else if (submitMode === 'submit' || submitMode === 'resubmit') {
    //   subconStatus === ContractStatus.Submit;
    // } else if (submitMode === 'reject') {
    //   subconStatus === ContractStatus.Reject;
    // } else if (submitMode === 'approve') {
    //   subconStatus === ContractStatus.Active;
    // }
    submitMode === 'draft'
      ? (subconStatus = ContractStatus.Draft)
      : submitMode === 'submit' || submitMode === 'resubmit'
      ? (subconStatus = ContractStatus.Submit)
      : submitMode === 'reject'
      ? (subconStatus = ContractStatus.Rejected)
      : (subconStatus = ContractStatus.Active);

    const finalInput = {
      ...generalInput,
      ...subcontractInput,
      ...ladInput,
      ...retentionInput,
      ...docNumInput,
      ...exceedTradeInput,
      contactPerson: contactPersonInput,
      subcontractStatus: subconStatus,
      // SubconTradeFile: finalFiles,
    };

    if (mode === 'add') {
      if (!proceed && !implementWbs) {
        subconBudgetFunction({
          variables: {
            amount: numberReturnValue(data?.subcontractSum),
            contractID: data?.contractID,
            costCategoryID: data?.costCategoryID,
          },
        });
      } else {
        createSubcontract({
          variables: {
            input: finalInput,
            wbsInput: subconTradeInput,
          },
        });
      }
    } else if (mode === 'edit' || mode === 'resubmit') {
      updateSubcontract({
        variables: {
          input: Object.assign({ ID: subcontractID }, finalInput),
          wbsInput: subconTradeInput,
        },
      });
    }
  };

  const { isDirty } = formState;

  return (
    <>
      {budgetLoading && <Loading />}
      {contactLoading && <Loading />}
      {subcontractorLoading && <Loading />}
      {subcontractLoading && <Loading />}
      {contractLoading && <Loading />}
      {tradeCostLoading && <Loading />}
      {costCategoryLoading && <Loading />}
      {wbsParentChildLoading && <Loading />}
      <MainHeader
        mainBtn="back"
        onClick={() => {
          mode === 'add' && !editType
            ? history.push('/home')
            : history.push(`/subcontract/submenu/${subcontractID}/menu/info`);
        }}
        smTitle={smTitle.SUBCONTRACTOR_APP}
        title={user?.companyName}
        currency={`MYR`}
        routeSegments={[{ name: 'Subcontracts ', current: true }]}
        rightRouteSegments={[
          { name: mode === 'add' ? 'New' : 'Edit', current: true },
        ]}
      />

      {mode !== 'add' && (
        <DynamicSubHeader
          title={getSubcontract[0]?.title}
          rightText={
            getSubcontract[0]?.revisedSubcontractSum ??
            getSubcontract[0]?.subcontractSum

            // <>
            //   <IconText
            //     icon={<img src={OrangeDollarIcon} style={{ width: '12px' }} />}
            //     font="xsTitle highlight-text"
            //     children={numberFormatter(
            //       getSubcontract[0]?.revisedSubcontractSum ??
            //         getSubcontract[0]?.subcontractSum,
            //     )}
            //   />
            // </>
          }
          rightTextIsMoney
          infoLine={getSubcontract[0]?.subcontractorDetail?.name}
          rightInfoLine={formatDate(getSubcontract[0]?.subcontractDate)}
        />
      )}

      {GeneralCondition() && (
        <SubconContext.Provider
          value={{
            mode,
            control,
            register,
            errors,
            setValue,
            watch,
            contConst,
            editType,
            subcontractID: subcontractID,
            setInfoFiles,
            setLADFiles,
            setSubconTradeFiles,
            subconTradeFiles,
            contractData: getContract as any,
            costCatData: getSubcontractTradeCostCategory,
            fetchCostCatData: fetchCostCategory,
            subcontractorData: getSubcontractor,
            wbsData: getWBSParentChild,
            disableCondition:
              mode !== 'add' && associateCheck?.associateChecking,
            disableVoCond: mode !== 'add' && associateCheck?.voChecking,
            setContractSum,
            setContractDate,
            subcontractData: getSubcontract,
            defaultContact: mode !== 'add' ? getContact : null,
            setDateData,
            setContractStartEnd,
            setContractID: setContractID,
            contractID: contractID,
            implementWbs: implementWbs,
            setImplementWbs: setImplementWbs,
            openSubmissionDialog: openSubmissionDialog,
            setTradeArr: setTradeArr,
            tradeArr: tradeArr,
            setOpenSubmissionDialog: setOpenSubmissionDialog,
            setOpenExceedDialog: setOpenExceedDialog,
            openExceedDialog: openExceedDialog,
            getCostCategory: getCostCategory,
            setFlagTrade: setFlagTrade,
          }}
        >
          <ContentWrapper footer multiDynamicInfo={mode !== 'add'}>
            {cond('info') && <SubconGeneralForm />}
            {cond('subconTrade') &&
              implementWbs &&
              process.env.REACT_APP_LOCAL_DB == 'local' && (
                <SubconTradePackage />
              )}
            {cond('subconTradeExceed') &&
              implementWbs &&
              process.env.REACT_APP_LOCAL_DB == 'local' && (
                <SubconTradeExceed />
              )}
            {cond('address') && <SubconAddressList />}
            {cond('contact') && <SubconContactList />}
            {cond('retention') && <SubconRetention />}
            {cond('lad') && <SubconLAD />}
            {cond('docNo') && <SubconDocNum />}
          </ContentWrapper>
        </SubconContext.Provider>
      )}

      <ConfirmationDialog
        exitDialog={exitDialog}
        setExitDialog={setExitDialog}
        path={
          () => (mode == 'add' ? history.push('/home') : null)
          // (mode === 'add' || mode === 'edit' || mode === 'resubmit') &&
          // !editType
          //   ? history.push(`/subcontract`)
          //   : history.push(`/subcontract/submenu/${consubconId}/detail`)
        }
      />

      <CommonDialog
        fullWidth={true}
        open={errorDia}
        onClose={() => setErrorDia(false)}
        sections={{
          header: {
            children: (
              <ListItem className="remove-padding">
                <ListItemText
                  primary={
                    <>
                      <span
                        className="xsTitle flex-space"
                        style={{ color: 'red' }}
                      >
                        Error! Duplicate Records
                      </span>
                    </>
                  }
                />
              </ListItem>
            ),
          },
          body: () => (
            <div>
              <span>
                {`${errMsg}. Please enter a Unique Title & Subcontract No.`}
              </span>
            </div>
          ),
          footer: {
            actions: [
              {
                displayText: 'Close',
                props: {
                  onClick: () => setErrorDia(false),
                  variant: 'contained',
                  color: 'primary',
                },
              },
            ],
          },
        }}
      />
    </>
  );
};
