import { useMenuOption } from '@contract-root/admin-react/src/helpers/Hooks/useMenuOption';
import { usePermissionChecker } from '@contract-root/admin-react/src/helpers/Hooks/usePermissionChecker';
import { formatDate } from '@contract-root/admin-react/src/helpers/StringNumberFunction/formatDate';
import { amtStr } from '@contract-root/admin-react/src/helpers/StringNumberFunction/numFormatter';
import {
  ContPermission,
  GetSubcontractTenderDocument,
  useDeleteSubcontractTenderMutation,
} from '@contract-root/contract-react/src/generated/graphql';
import { useFuseSearch } from '@ifca-root/react-component/src/helpers/Hooks/useSearch';
import OrangeDollarIcon from '@ifca-root/react-component/src/assets/icons/Money/dollar-orange.svg';
import FloatButton from '@ifca-root/react-component/src/components/Button/FloatButton';
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList';
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import {
  SearchFilterV2,
  useSearchFilterV2,
} from '@ifca-root/react-component/src/components/SpecialBar/SearchFilter/SearchFilterV2';
import IconText from '@ifca-root/react-component/src/components/Typography/IconText';
import {
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
} from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import { SwitchDialog } from 'components/SwitchDialog/SwitchDialog';
import AppContext from 'containers/App/Store/AppContext';
import SnackBarContext from 'containers/App/Store/SnackBarContext';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';

export const SubconTenderListing = (props: any) => {
  let history = useHistory();
  const { contractID, subconTenderID, subcontractor }: any = useParams();
  const { setSnackBarMsg, setOpenSnackBar }: any = useContext(
    SnackBarContext as any,
  );

  const { anchorEl, menu, handleClick, handleClose } = useMenuOption();
  const [contractSum, setContractSum] = useState<any>();

  const [labelState, setLabelState] = useState({ ID: '', title: '' });

  const { getSubcontractTender } = props;
  const { setToDoCount } = props;

  // const { loading, error, data } = useLoggedInPlatformUserProfileQuery();
  const getSearchDropdown = localStorage?.getItem(`searchDropdown`);
  const [status, setStatus] = useState<string>(getSearchDropdown ?? 'ACTIVE');

  const user = JSON.parse(localStorage.getItem('loggedInUser'));

  useEffect(() => {
    if (getSubcontractTender && getSubcontractTender?.length > 0) {
      setOriginalListing(getSubcontractTender, v => {
        v['closeDateFormatted'] = formatDate(v.closeDate);
        v['tenderDateFormatted'] = formatDate(v.tenderDate);
      });
    } else if (getSubcontractTender?.length === 0) {
      setOriginalListing([]);
    }
  }, [getSubcontractTender]);

  const [menuDelete, setMenuDelete] = useState('');

  //Mutation for DeleteSubconTender
  const [
    deleteSubcontractTender,
    { loading: mutationLoading, error: mutationError },
  ] = useDeleteSubcontractTenderMutation({
    onError: error => {
      console.log('ERROR', error);
    },
    onCompleted: () => {
      setOpenSnackBar(true);
    },
  });
  const [deleteInvitees, setDeleteInvitees] = useState(false);

  const deleteSubconTender = subconTenderID => {
    deleteSubcontractTender({
      variables: {
        input: {
          ID: menu?.obj?.ID,
        },
      },
      refetchQueries: [
        {
          query: GetSubcontractTenderDocument,
          variables: {
            contractID: contractID,
            orderByAsc: 'subconTenderStatus',
          },
        },
      ],
    });
  };

  // Search part
  const {
    filteredList,
    handleSearch,
    setOriginalListing,
    originalList,
  } = useFuseSearch();
  const getSearch = localStorage?.getItem('searchFilter');
  const [filter, setFilter] = useState(false);
  const [filterState, setFilterState] = useState(null);
  const { globalState, dispatch }: any = useContext(AppContext as any);
  const [search, setSearch] = useState('');

  useEffect(() => {
    if (getSearch && originalList) {
      handleSearch(getSearch + '', [
        'title',
        'closeDateFormatted',
        'tenderDateFormatted',
        'budgetAmt',
      ]);
    }
  }, [getSearch, originalList]);

  const {
    searchInput,
    onChangeSearchInput,
    onCloseSearchInput,
    dropdownEl,
    selectedOption,
    handleOpenDropdownEl,
    handleCloseDropdownEl,
    handleOptionChange,
  } = useSearchFilterV2({
    handleSearch,
    searchKey: [
      'title',
      'closeDateFormatted',
      'tenderDateFormatted',
      'budgetAmt',
    ],
    defaultValueSearch: getSearch,
    localStorageSearchKey: 'searchFilter',
    defaultValueDropdown: getSearchDropdown || 'ALL',
    localStorageDropdownKey: 'searchDropdown',
  });

  //---------------------------SWITCH DIALOG--------------------------

  const [change, setSwitch] = useState(false);

  const handlechange = value => {
    setLabelState({ ID: value?.ID, title: value?.title });
    setContractSum(value?.revisedContractSum ?? value?.contractSum);
    history.push(`/subcon-tender/${value?.ID}`);
  };

  const handleClickSwitchDialog = () => {
    setSwitch(true);
  };
  const handleCloseSwitchDialog = () => {
    setSwitch(false);
  };

  const { handlePermDisabled } = usePermissionChecker();

  return (
    <>
      <div className="collab-tab-subheader">
        <SearchFilterV2
          search
          searchInput={searchInput}
          onChangeSearchInput={onChangeSearchInput}
          onCloseSearchInput={onCloseSearchInput}
          dropdownBorderRadius="4px"
          dropdownOptions={[
            {
              name: 'Active',
              ID: 'ACTIVE',
            },
            {
              name: 'Closed',
              ID: 'CLOSED',
            },
            {
              name: 'All',
              ID: 'ALL',
            },
          ]}
          dropdownEl={dropdownEl}
          selectedOption={selectedOption}
          handleOpenDropdownEl={handleOpenDropdownEl}
          handleCloseDropdownEl={handleCloseDropdownEl}
          handleOptionChange={handleOptionChange}
        />
      </div>

      <ContentWrapper float>
        <List className="core-list" style={{ marginTop: '45px' }}>
          {filteredList === undefined || filteredList?.length === 0 ? (
            <EmptyList
              title="No Record found"
              subtitle="Add a new record now."
            />
          ) : (
            filteredList?.map((el, index) => (
              <ListItem
                key={index}
                selected={el.subconTenderStatus === 'CLOSED'}
              >
                <ListItemText
                  primary={
                    <>
                      <span
                        className="xsTitle click-text flex-space"
                        // onClick={() =>
                        //   history.push(
                        //     el.contractStatus === 'TERMINATED' ||
                        //       el.contractStatus === 'CLOSED'
                        //       ? null
                        //       : `/subcon-tender/${el.ID}`,
                        //   )
                        // }
                      >
                        {el.title}
                      </span>
                      <span className="xxTitle">
                        {/* <span className=" color-orange">{`MYR`} &nbsp;</span> */}
                        <span className="color-orange">
                          <IconText
                            icon={
                              <img
                                src={OrangeDollarIcon}
                                style={{ width: '12px' }}
                              />
                            }
                            font="highlight-text xxTitle"
                            children={amtStr(el.budgetAmt)}
                          ></IconText>
                        </span>
                      </span>
                    </>
                  }
                  secondary={
                    <>
                      <span className="desc flex-space">
                        <span>Open: </span>
                        <span className="highlight-text">
                          {el.subconInvitation.length}{' '}
                        </span>
                        <span>Tenderer(s)</span>
                      </span>

                      <span className="desc">
                        {formatDate(el.startDate)} - {formatDate(el.closeDate)}
                      </span>
                    </>
                  }
                />
                <ListItemSecondaryAction>
                  {/* <IconButton
                    edge="end"
                    aria-label="delete"
                    aria-controls="menu-list"
                    aria-haspopup="true"
                    onClick={e =>
                      el.subconTenderStatus === 'CLOSED'
                        ? ''
                        : handleClick(e, el?.ID, index, el)
                    }
                  >
                    <MoreVert />
                  </IconButton> */}
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    aria-controls="menu-list"
                    aria-haspopup="true"
                    onClick={e => handleClick(e, el?.ID, index, el)}
                  >
                    <MoreVert />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))
          )}

          {/* <div>You are on Subcon Tender Subcontract Listing</div> */}
        </List>
      </ContentWrapper>
      <Menu
        id="menu-list"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={handleClose}
      >
        {menu?.obj?.subconTenderStatus !== 'CLOSED' ? (
          <>
            {/* <MenuItem
              disabled={handlePermDisabled({
                contractID: contractID,
                permEnum: ContPermission.SubconTenderTenderInviteesView,
              })}
              onClick={() => history.push(`/home/${menu.ID}/tender-detail`)}
            >
              <span className="">View Tender</span>
            </MenuItem>

            <MenuItem
              disabled={handlePermDisabled({
                contractID: contractID,
                permEnum: ContPermission.SubconTenderEvaluationSelectionCreate,
              })}
              onClick={() =>
                history.push({
                  pathname: `/home/${menu.ID}/tender-invitees-submission`,
                  state: menu.obj,
                })
              }
            >
              <span className="">Tender Submission</span>
            </MenuItem> */}
            <MenuItem
              disabled={handlePermDisabled({
                contractID: contractID,
                permEnum: ContPermission.SubconTenderUpdate,
              })}
              onClick={() =>
                history.push({
                  pathname: `/home/${menu?.obj?.contractID}/${menu.ID}/edit`,
                  state: menu.obj,
                })
              }
            >
              <span className="">Edit Tender Specs</span>
            </MenuItem>

            <MenuItem
              disabled={handlePermDisabled({
                contractID: contractID,
                permEnum: ContPermission.SubconTenderTenderInviteesView,
              })}
              onClick={() =>
                history.push(
                  `/subcon-tender/${menu?.obj?.contractID}/${menu.ID}/tender-invitees`,
                )
              }
            >
              <span className="">Tender Invitees</span>
            </MenuItem>

            <MenuItem
              disabled={handlePermDisabled({
                contractID: contractID,
                permEnum: ContPermission.SubconTenderEvaluationSelectionCreate,
              })}
              onClick={() =>
                history.push({
                  pathname: `/subcon-tender/${menu?.obj?.contractID}/${menu.ID}/eval-and-select`,
                  state: menu.obj,
                })
              }
            >
              <span className="">Evaluation & Selection</span>
            </MenuItem>

            <MenuItem
              disabled={handlePermDisabled({
                contractID: contractID,
                permEnum: ContPermission.SubconTenderDelete,
              })}
              onClick={() => {
                deleteSubconTender(menu?.obj?.ID);
              }}
            >
              <span className="">Delete</span>
            </MenuItem>
          </>
        ) : (
          <>
            <MenuItem
              disabled={handlePermDisabled({
                contractID: contractID,
                permEnum: ContPermission.SubconTenderView,
              })}
              onClick={() => {
                history.push({
                  pathname: `/home/${menu.ID}/tender-detail`,
                });
              }}
            >
              <span className="">View Tender</span>
            </MenuItem>

            <MenuItem
              onClick={() => {
                history.push({
                  pathname: `/home/${menu.ID}/tender-invitees-submission`,
                  state: menu.obj,
                });
              }}
            >
              <span className="">Tender Submission</span>
            </MenuItem>
          </>
        )}
      </Menu>
      <FloatButton
        data-qa="addButton"
        onClick={() =>
          history.push(`/subcon-tender/${menu?.obj?.contractID}/add`)
        }
      />

      <CommonDialog
        fullWidth={true}
        open={deleteInvitees}
        onClose={() => setDeleteInvitees(false)}
        sections={{
          header: {
            children: (
              <ListItem className="remove-padding">
                <ListItemText
                  primary={
                    <>
                      <span
                        className="smTitle flex-space"
                        style={{ color: 'red' }}
                      >
                        Error! Subcon Tender Invitation Violation
                      </span>
                    </>
                  }
                />
              </ListItem>
            ),
          },
          body: () => (
            <div>
              <span>
                <span
                  className="highlight-text"
                  // style={{ textTransform: 'capitalize' }}
                >
                  {
                    'Please uninvite subcontractors in current Subcon Tender before deleting this record.'
                  }
                </span>
              </span>
            </div>
          ),
          footer: {
            actions: [
              {
                displayText: 'Close',
                props: {
                  onClick: () => setDeleteInvitees(false),
                  variant: 'contained',
                  color: 'primary',
                },
              },
            ],
          },
        }}
      />
    </>
  );
};
