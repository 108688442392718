import React, { lazy } from 'react';
import { SubcontractMain } from './SubcontractMain';

const Home = lazy(() =>
  import('./Home').then(module => ({
    default: module.Home,
  })),
);

const homeRoutes = [
  {
    props: { exact: true, path: '/' },
    component: <SubcontractMain />,
  },
  {
    props: { exact: true, path: '/home' },
    component: <SubcontractMain />,
  },
  {
    props: { exact: true, path: '/alternate' },
    component: <Home />,
  },
];

export default homeRoutes;
