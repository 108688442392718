import { useFuseSearch } from '@ifca-root/react-component/src/helpers/Hooks/useSearch';
import { formatDate } from '@contract-root/admin-react/src/helpers/StringNumberFunction/formatDate';
import { numberFormatter } from '@contract-root/admin-react/src/helpers/StringNumberFunction/numFormatter';
import BizInsight from '@ifca-root/react-component/src/assets/contractIcons/business_insight.svg';
import ContractMgmt from '@ifca-root/react-component/src/assets/contractIcons/contract_mgmt.svg';
import Subcontractor from '@ifca-root/react-component/src/assets/contractIcons/subcontractor.svg';
import SystemAdmin from '@ifca-root/react-component/src/assets/contractIcons/system_admin.svg';
import Claims from '@ifca-root/react-component/src/assets/hrIcons/claims-app.svg';
import OrangeDollarIcon from '@ifca-root/react-component/src/assets/icons/Money/dollar-orange.svg';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import {
  SearchFilterV2,
  useFilterDialog,
  useSearchFilterV2,
} from '@ifca-root/react-component/src/components/SpecialBar/SearchFilter/SearchFilterV2';
import IconText from '@ifca-root/react-component/src/components/Typography/IconText';
import {
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core';
import { KeyboardArrowRight } from '@material-ui/icons';
import AppContext from 'containers/App/Store/AppContext';
import Fuse from 'fuse.js';
import {
  ContractPermission,
  useGetSubcontractLazyQuery,
  useGetSubcontractorLazyQuery,
  useLoggedInPlatformUserProfileQuery,
} from 'generated/graphql';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { usePermissionChecker } from '@contract-root/admin-react/src/helpers/Hooks/usePermissionChecker';
import FloatButton from '@ifca-root/react-component/src/components/Button/FloatButton';
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList';

export const SubcontractListing = props => {
  const getSearchDropdown = localStorage?.getItem(`searchDropdown`);
  const { subconId }: any = useParams();
  let history = useHistory();
  let localtion = useLocation();
  let subConList;
  const { type, consubcon } = props;

  const { globalState, dispatch }: any = useContext(AppContext as any);
  const [subcontractStatus, setSubcontractStatus] = useState<string>(
    getSearchDropdown ?? 'ACTIVE',
  );

  useEffect(() => {
    if (!!getSearchDropdown) {
      setSubcontractStatus(getSearchDropdown);
    }
  }, [getSearchDropdown]);

  //filter............
  const {
    filteredList,
    handleSearch,
    setOriginalListing,
    originalList,
  } = useFuseSearch();

  const getSearch = localStorage?.getItem('searchFilter');
  const [filter, setFilter] = useState(false);
  const [filterState, setFilterState] = useState(null);

  const { handlePermDisabled } = usePermissionChecker();
  const user = JSON.parse(localStorage.getItem('loggedInUser'));
  const [search, setSearch] = useState('');
  const handleFilterListing = data => {
    if (selectedOption == 'ACTIVE') return data;
    if (selectedOption == 'ALL') return data;

    return data['SubcontractStatus'] === selectedOption;
  };

  const {
    searchInput,
    onChangeSearchInput,
    onCloseSearchInput,
    dropdownEl,
    selectedOption,
    handleOpenDropdownEl,
    handleCloseDropdownEl,
    handleOptionChange,
  } = useSearchFilterV2({
    handleSearch,
    searchKey: [
      'title',
      'subcontractDetail.title',
      'subcontractDetail.tradeDetail.name',
      'subcontractSummary.active',
      'subcontractSummary.closed',
      'subcontractSummary.totalSubconSum',
    ],
    defaultValueSearch: getSearch,
    localStorageSearchKey: 'searchFilter',
    defaultValueDropdown: getSearchDropdown || 'NONE',
    localStorageDropdownKey: 'searchDropdown',
  });

  // HANDLE ALL QUERIES
  const { getSubcontract } = props;
  const { setNotiCount } = props;

  const handleDrawer = () => {
    dispatch({
      type: 'drawerOpen',
      payload: !globalState.drawerOpen,
    });
  };

  const { openFilterDialog, handleFilterDate, filterDialog } = useFilterDialog({
    dateRange: {
      title: 'Submitted Date',
      key: 'submittedDate',
    },
  });

  useEffect(() => {
    if (getSubcontract && getSubcontract?.length > 0) {
      setOriginalListing(getSubcontract);
    }
  }, [getSubcontract]);

  useEffect(() => {
    if (getSearch && !!originalList) {
      const keys = [
        'docNo',
        'docRef',
        'description',
        'approvedAmt',
        'SubcontractStatus',
        'docDate',
      ];
      const options = {
        shouldSort: true,
        threshold: 0.6,
        ignoreLocation: true,
        keys: keys,
      };

      const myFuse = new Fuse(originalList, options);
      const result = myFuse.search(getSearch);
      const val = result?.map(x => x.item);
      if (val.length !== 0) {
        handleSearch(getSearch + '', keys);
      }
    }
  }, [getSearch, originalList]);

  // ............................................................................................................
  //////////////////// All the SubcontractorX Listing ///////////////////////////
  return (
    <>
      <div className="collab-tab-subheader">
        <SearchFilterV2
          search
          searchInput={searchInput}
          onChangeSearchInput={onChangeSearchInput}
          onCloseSearchInput={onCloseSearchInput}
          dropdownBorderRadius="4px"
          dropdownOptions={[
            {
              name: 'Active',
              ID: 'ACTIVE',
            },
            {
              name: 'Closed',
              ID: 'CLOSED',
            },
            {
              name: 'Terminated',
              ID: 'TERMINATED',
            },
            {
              name: 'All',
              ID: 'NONE',
            },
          ]}
          dropdownEl={dropdownEl}
          selectedOption={selectedOption}
          handleOpenDropdownEl={handleOpenDropdownEl}
          handleCloseDropdownEl={handleCloseDropdownEl}
          handleOptionChange={handleOptionChange}
          filter
          onClickFilter={openFilterDialog}
        />
      </div>

      <ContentWrapper float>
        <List className="core-list" style={{ marginTop: '45px' }}>
          {filteredList === undefined || filteredList?.length === 0 ? (
            <EmptyList
              title="No Record found"
              subtitle="Add a new record now."
            />
          ) : (
            filteredList?.map((el, index) => (
              <ListItem key={index}>
                <ListItemText
                  primary={
                    <>
                      <span className="xsTitle click-text flex-space">
                        {el.title}
                      </span>
                      <span className="xxTitle">
                        {/* <span className=" color-orange">{`MYR`} &nbsp;</span> */}
                        <IconText
                          icon={
                            <img
                              src={OrangeDollarIcon}
                              style={{ width: '12px' }}
                            />
                          }
                          font="xxTitle highlight-text"
                          //number
                          children={numberFormatter(el?.subcontractSum)}
                          // children={amtStr(el?.subcontractSum)}
                        ></IconText>
                        {/* <span className=" highlight-text">
                        {amtStr(el?.subcontractSum)}
                      </span> */}
                      </span>
                    </>
                  }
                  secondary={
                    <>
                      <span className="desc">
                        <span className="fw-medium">
                          {el?.subcontractorDetail?.name}
                        </span>
                      </span>

                      <span className="desc flex-space"> </span>

                      <span className="desc">
                        {' '}
                        {formatDate(el?.subcontractDate)}
                        {/* <span className="desc">-</span>
                      {formatDate(el?.endDate)} */}
                      </span>
                    </>
                  }
                />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    //key={index}
                    onClick={() =>
                      history.push({
                        // pathname: `/contract/undefined/subcontract`,
                        pathname: `/subcontract/submenu/${el?.ID}/menu`,
                      })
                    }
                  >
                    <KeyboardArrowRight />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))
          )}
        </List>
      </ContentWrapper>

      <FloatButton
        // disabled={
        //   getSubcontract?.find(v => v?.ID === labelState?.ID)?.contractStatus !==
        //   'ACTIVE'
        // }
        data-qa="addButton"
        onClick={() => history.push(`/subcontract/add`)}
      />

      {/* dialog........................................ */}
    </>
  );
};
