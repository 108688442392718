import React, { useContext } from 'react';
import { SubconContext } from './SubconContext';
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents';
import RegularTextController from 'containers/SubcontractModule/ConSubconHelper/TextFieldController';
import NumberFormatController from 'containers/SubcontractModule/ConSubconHelper/NumberFormatController';

export const SubconDocNum = () => {
  const {
    mode,
    contConst,
    disableCondition,
    disableVoCond,
    contractData,
    subcontractData,
    // conSubcon,
    errors,
  } = useContext(SubconContext);

  const editData = mode !== 'add' ? subcontractData[0] : null;

  return (
    <>
      <CardContents
        section={{
          header: {
            title: 'Document Number Setup',
          },
        }}
      >
        <RegularTextController
          {...contConst}
          name={'claimPrefix'}
          className="left"
          label={'Claim Prefix'}
          fullWidth={true}
          required={true}
          disabled={disableCondition}
          defaultValue={
            mode !== 'add' ? editData?.latestRunNum?.claimPrefix : 'IPC'
          }
          errors={errors}
        />

        <NumberFormatController
          {...contConst}
          name="claimRunNum"
          label="Claim Starting No."
          className="right"
          multiline={true}
          disabled={disableCondition}
          defaultValue={
            mode !== 'add' ? editData?.latestRunNum?.claimRunNum : 1
          }
          errors={errors}
        />

        <RegularTextController
          {...contConst}
          name={'voPrefix'}
          className="left"
          label={'VO Prefix'}
          fullWidth={true}
          required={true}
          disabled={disableVoCond}
          defaultValue={
            mode !== 'add' ? editData?.latestRunNum?.voPrefix : 'IPC'
          }
          errors={errors}
        />

        <NumberFormatController
          {...contConst}
          name="voRunNum"
          label="VO Starting No."
          className="right"
          multiline={true}
          disabled={disableVoCond}
          defaultValue={mode !== 'add' ? editData?.latestRunNum?.voRunNum : 1}
          errors={errors}
        />
      </CardContents>
    </>
  );
};
