import { IControllerConstantProps } from 'containers/SubcontractModule/ConSubconHelper/ConSubconInterface';
import { Dispatch, createContext } from 'react';

interface IContextProps {
  mode: 'add' | 'edit' | 'resubmit';
  //   conSubcon: 'contract' | 'subcontract';
  control: any;
  register: any;
  watch: any;
  errors: any;
  setValue?: any;
  handleSubmit?: any;
  contConst?: IControllerConstantProps;
  disableCondition?: boolean;
  disableVoCond?: boolean;
  defaultContact?: any;
  defaultAddress?: any;
  subcontractID?: string;
  editType?: string;
  formState?: any;
  setDateData: Dispatch<{
    startDate: string;
    endDate: string;
    projDate: string;
  }>;

  // props for files
  setInfoFiles?: Dispatch<any>;
  setLADFiles?: Dispatch<any>;
  implementWbs?: boolean;
  setImplementWbs?: Dispatch<boolean>;
  // props for contract form
  customerData?: any;
  bankData?: any;
  getBankAccount?: any;
  setSelectedBank?: any;
  clearErrors?: any;
  bankAccountLoading?: any;
  selectedBank?: any;

  // props for subcontract form
  contractData?: any;
  subcontractData?: any;
  costCatData?: any;
  fetchCostCatData?: any;
  subcontractorData?: any;
  setContractSum?: Dispatch<number>;
  setContractDate?: Dispatch<any>;
  getCostCategory?: any;

  setContractStartEnd?: Dispatch<{ conStart: null; conEnd: null }>;
  // props for address
  addrRequiredError?: boolean;

  //props for subcontract trade form
  wbsData?: any;
  contractID?: any;
  setContractID?: Dispatch<any>;
  setTradeArr?: Dispatch<any>;
  tradeArr?: any;
  setSubconTradeFiles?: Dispatch<any>;
  subconTradeFiles?: any;
  openSubmissionDialog?: any;
  setOpenSubmissionDialog?: Dispatch<any>;
  openExceedDialog?: any;
  setOpenExceedDialog?: Dispatch<any>;
  setFlagTrade?: Dispatch<any>;
}

export const SubconContext = createContext<IContextProps>(null);
