import { useMenuOption } from '@contract-root/admin-react/src/helpers/Hooks/useMenuOption';
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents';
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList';
import {
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemSecondaryAction,
  TextField,
  Menu,
  MenuItem,
} from '@material-ui/core';
import MainSite from 'assets/icons/main-site.svg';
import { MoreVert } from '@material-ui/icons';
import { IAddressParentProps } from 'containers/SubcontractModule/ConSubconHelper/ConSubconInterface';
import React, { useContext, useEffect, useState } from 'react';
import { SubconContext } from '../SubconContext';
import { addressFormat } from '@contract-root/admin-react/src/helpers/AddressHandler/AddressFormat';
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog';
import IconItem from '@ifca-root/react-component/src/components/Avatar/IconItem';
import { SubconAddressForm } from './SubconAddressForm';

export const SubconAddressList = () => {
  const {
    // conSubcon,
    register,
    setValue,
    watch,
    errors,
    contractData,
    defaultAddress,
    addrRequiredError,
  } = useContext(SubconContext);

  const [address, setAddress] = useState<IAddressParentProps[]>(
    defaultAddress || [],
  );

  const {
    anchorEl,
    menu,
    handleClick,
    handleClose,
    resetMenu,
  } = useMenuOption();
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openDeleteDialog, setDeleteDialog] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const sectionSubcon = {
    header: {
      title: 'Project Address',
    },
  };

  useEffect(() => {
    register('address');
    setValue('address', address);
  }, [address]);

  useEffect(() => {
    if (watch('contractID') && watch('siteID')) {
      let currAddress = contractData
        ?.find(x => x?.ID === watch('contractID'))
        ?.site?.find(v => v?.ID === watch('siteID'));
      setAddress([
        {
          ID: currAddress?.ID,
          siteName: currAddress?.name,
          address: currAddress?.address?.address,
          city: currAddress?.address?.city,
          country: currAddress?.address?.country,
          postCode: currAddress?.address?.postCode,
          // mainSite: currAddress?.adress?.mainSite,
          state: currAddress?.address?.state,
        },
      ]);

      //// if one day they can add new address use this =>
      //// whether user add the address first or later
      // setAddress([...address,
      // {
      //   siteName: currAddress?.name,
      //   address: currAddress?.address?.address,
      //   city: currAddress?.address?.city,
      //   country: currAddress?.address?.country,
      //   postCode: currAddress?.address?.postCode,
      //   state: currAddress?.address?.state,
      // }
      // ])
    }
  }, [watch('contractID'), watch('siteID')]);

  return (
    <>
      <CardContents section={{ ...sectionSubcon }}>
        {address?.length === 0 ? (
          <div
            style={{
              width: '100%',
              justifyContent: 'center',
            }}
          >
            <EmptyList
              title={
                watch('contractID')
                  ? 'No Site Selected'
                  : 'No Contract Selected'
              }
              subtitle={
                watch('contractID')
                  ? 'Select site from project address'
                  : 'Select contract to enable address selection'
              }
              errorMessage={errors?.address?.message}
            />
          </div>
        ) : (
          address?.map((el, index) => {
            return (
              <>
                <List className="borderless-list full content-wrap">
                  <ListItem key={el?.ID}>
                    <TextField
                      label="Site Name"
                      className="smTitle highlight-text"
                      value={`${el?.siteName || `N/A`}`}
                      multiline
                      InputProps={{
                        readOnly: true,
                        disableUnderline: true,
                        endAdornment: el?.mainSite ? (
                          <InputAdornment position="end">
                            <IconItem
                              image={MainSite}
                              imageType="svg"
                              badgeType="default"
                            />
                          </InputAdornment>
                        ) : (
                          <></>
                        ),
                      }}
                    />
                    <TextField
                      label="Address"
                      key={el?.ID}
                      className="smTitle highlight-text"
                      // value={`${el?.address}, ${el?.city}, ${el?.state}, ${el?.postCode}, ${el?.country}`}
                      value={addressFormat(el)}
                      multiline
                      InputProps={{
                        readOnly: true,
                        disableUnderline: true,
                      }}
                    />
                  </ListItem>
                </List>
              </>
            );
          })
        )}
      </CardContents>
      <Menu
        id="menu-list"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={handleClose}
      >
        <MenuItem
          onClick={() => {
            setOpenDialog(true);
            setIsEdit(true);
          }}
        >
          <span>Edit</span>
        </MenuItem>

        <MenuItem onClick={() => setDeleteDialog(true)}>
          <span>Delete</span>
        </MenuItem>
      </Menu>

      <SubconAddressForm
        mode={isEdit ? 'edit' : 'add'}
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        isEdit={isEdit}
        // append={append}
        // remove={remove}
        menu={menu}
        setValue={setAddress}
        value={address}
        // fields={fields}
      />

      <CommonDialog
        fullWidth
        open={openDeleteDialog}
        onClose={() => setDeleteDialog(false)}
        sections={{
          header: {
            title: `Project Address`,
            rightText: 'Delete',
          },
          body: () => (
            <div>
              <span className="mdDesc full">
                Are you sure you want to delete this address?
              </span>
              <br />
              <span className="xsTitle content-wrap full text-noflow">
                {menu?.obj?.siteName},{addressFormat(menu?.obj)}
              </span>
            </div>
          ),
          footer: {
            actions: [
              {
                displayText: 'Cancel',
                props: {
                  onClick: () => {
                    setDeleteDialog(false);
                  },
                  variant: 'contained',
                  color: 'primary',
                },
              },
              {
                displayText: 'Confirm',
                props: {
                  onClick: () => {
                    setDeleteDialog(false);
                    let temp = address;
                    temp?.splice(menu?.index, 1);
                    setAddress([...temp]);
                  },
                  variant: 'contained',
                  color: 'primary',
                },
              },
            ],
          },
        }}
      />
    </>
  );
};
