import React, { useContext, useEffect, useState } from 'react';
import { SubconContext } from './SubconContext';
import useUploadAttachment from '@ifca-root/react-component/src/utils/hooks/useUploadAttachment';
import {
  useGetBankAccountLazyQuery,
  useGetConTaxSchemeQuery,
} from '@contract-root/admin-react/src/generated/graphql';
import {
  BankAccountStatus,
  CommonStatus,
  TaxClass,
  useDocumentListingLazyQuery,
} from 'generated/graphql';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { Controller } from 'react-hook-form';
import DateFnsUtils from '@date-io/date-fns';
import BigNumber from 'bignumber.js';
import { amtNumStr } from '@contract-root/admin-react/src/helpers/StringNumberFunction/numFormatter';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents';
import DropdownController from '../../ConSubconHelper/DropDownController';
import { formatDate } from '@contract-root/admin-react/src/helpers/StringNumberFunction/formatDate';
import { addressFormat } from '@contract-root/admin-react/src/helpers/AddressHandler/AddressFormat';
import RegularTextController from 'containers/SubcontractModule/ConSubconHelper/TextFieldController';
import { changeDateFormat } from '@ifca-root/react-component/src/helpers/Functions/dateFunction';
import NumberFormatController from 'containers/SubcontractModule/ConSubconHelper/NumberFormatController';
import CheckBoxController from 'containers/SubcontractModule/ConSubconHelper/CheckBoxController';
import { FileUploadInput } from '@ifca-root/react-component/src/components/Input/FileUploadInput';
import { UploadPreview } from '@ifca-root/react-component/src/components/Avatar/UploadPreview';

export const SubconGeneralForm = () => {
  const {
    mode,
    // conSubcon,
    watch,
    errors,
    register,
    control,
    setValue,
    contConst,
    customerData,
    setInfoFiles,
    contractData,
    costCatData,
    subcontractorData,
    disableCondition,
    subcontractData,
    setContractSum,
    setContractDate,
    subcontractID,
    editType,
    setDateData,
    setSelectedBank,
    clearErrors,
    selectedBank,
    getCostCategory,
    setContractStartEnd,
    setContractID,
    implementWbs,
    setImplementWbs,
  } = useContext(SubconContext);

  const editData = subcontractData[0];

  const user = JSON.parse(localStorage.getItem('loggedInUser'));
  const {
    files,
    handleUploadChange,
    previewFiles,
    remove: removeFile,
    handleEditUpload,
  } = useUploadAttachment();

  const [currentTax, setCurrTax] = useState<any>({});
  const [calcAmt, setCalcAmt] = useState<number>(null);

  //checkbox bank
  const [bankChecked, setBankChecked] = useState(
    !!editData?.bankAccountID ? true : false,
  );

  const {
    loading: taxLoading,
    data: { getConTaxScheme } = { getConTaxScheme: [] },
  } = useGetConTaxSchemeQuery({
    // variables: {
    //   commonStatus: CommonStatus.Active,
    // },
  });

  const [
    loadBankAccount,
    {
      called: bankAccountCalled,
      loading: bankAccountLoading,
      data: { getBankAccount } = { getBankAccount: [] },
    },
  ] = useGetBankAccountLazyQuery({
    fetchPolicy: 'network-only',
    variables: {
      status: BankAccountStatus.Active,
    },
  });

  useEffect(() => {
    if (!!files) setInfoFiles(files);
  }, [files, mode]);

  useEffect(() => {
    if (bankChecked) {
      loadBankAccount();
    }
  }, [bankChecked]);

  useEffect(() => {
    if (
      (mode === 'edit' || mode === 'resubmit') &&
      getConTaxScheme?.length > 0 &&
      !!editData
    ) {
      setCurrTax({
        ...getConTaxScheme?.filter(v => v?.ID === editData?.taxSchemeID)[0],
      });
    }
  }, [mode, getConTaxScheme, editData]);

  useEffect(() => {
    if (currentTax?.taxEffective?.length > 0) {
      setCalcAmt(
        calcTotalAmtWithTax(
          watch('subcontractSum'),
          currentTax?.taxEffective[0]?.taxRate,
        ),
      );
    }
  }, [watch('subcontractSum'), currentTax]);

  const taxCondition = () => {
    if (mode === 'add') return true;
    else return !taxLoading;
  };

  const [
    documents,
    {
      data: { DocumentListing } = { DocumentListing: [] },
      loading: documentLoading,
      error: documentError,
    },
  ] = useDocumentListingLazyQuery({
    fetchPolicy: 'network-only',
    variables: {
      refID: subcontractID,
      hasDescription: false,
    },
    onCompleted: ({ DocumentListing }) => {
      handleEditUpload(DocumentListing);
      previewFiles.push(...DocumentListing?.map(v => v.fileURL));
    },
  });

  useEffect(() => {
    if (mode === 'edit' || mode === 'resubmit') documents();
  }, [mode]);

  useEffect(() => {
    setDateData({
      startDate: watch('startDate'),
      endDate: watch('endDate'),
      projDate: watch('subcontractDate'),
    });
  }, [
    watch('startDate'),
    watch('endDate'),
    watch('contractDate'),
    watch('subcontractDate'),
  ]);

  useEffect(() => {
    let conDate = contractData?.find(v => v?.ID === watch('contractID'));

    setContractStartEnd({
      conStart: conDate?.contractDate,
      conEnd: conDate?.endDate,
    });
  }, [watch('contractID')]);

  ///////////////// Handle Date
  const [contractDateInfo, setContractDateInfo]: any = useState();
  const handleContractDateChange = data => {
    let selectedContract = contractData?.find(
      el => el?.ID == data?.target?.value,
    );
    setContractDateInfo({
      awardedDate: selectedContract?.contractDate,
      startDate: selectedContract?.startDate,
      endDate: selectedContract?.endDate,
    });
  };

  return (
    <>
      {taxLoading && <Loading />}
      {documentLoading && <Loading />}
      {bankAccountLoading && <Loading />}
      <CardContents
        section={{
          header: { title: `Subcontract Details` },
        }}
      >
        <>
          <DropdownController
            {...contConst}
            name="contractID"
            label="Select Main Contract"
            fullWidth
            required
            errors={errors}
            dropDownData={contractData}
            dropDownShowMenu="title"
            dropDownValue="ID"
            disabled={disableCondition}
            defaultValue={editData?.contractID}
            onChange={e => {
              let contVal = contractData?.find(v => v?.ID === e?.target?.value);
              let conSum = contVal?.revisedContractSum || contVal?.contractSum;
              let conDate = contVal?.contractDate;
              setContractSum(conSum);
              setContractDate(conDate);
              // setContractID(e?.target?.value);
              setImplementWbs(
                contractData?.find(v => v?.ID === e?.target?.value)
                  ?.implementWbs,
              );

              handleContractDateChange(e);
            }}
          />
          {!!contractDateInfo && (
            <>
              <div className="content-wrap">
                <div className="mdLabel">{`Awarded: ${formatDate(
                  contractDateInfo?.awardedDate,
                )}`}</div>
              </div>
              <div className="content-wrap">
                <div className="mdLabel">{`Period: ${formatDate(
                  contractDateInfo?.startDate,
                )} - ${formatDate(contractDateInfo?.endDate)}`}</div>
              </div>
            </>
          )}
        </>

        {watch('contractID') && (
          <DropdownController
            {...contConst}
            name={'siteID'}
            label="Select Project Address"
            fullWidth={true}
            required={true}
            errors={errors}
            defaultValue={editData?.site?.ID}
            dropDownData={
              contractData?.filter(v => v.ID === watch('contractID'))[0]?.site
            }
            dropDownValue={'ID'}
            customDropDownShowMenu={(el: any) => {
              return (
                // <div>
                //   {el?.name} - {el?.address?.address} {el?.address?.city},{' '}
                //   {el?.address?.state}, {el?.address?.postCode}
                // </div>
                <div>
                  {el?.name} - {addressFormat(el?.address)}
                </div>
              );
            }}
          />
        )}

        <DropdownController
          {...contConst}
          name={'subcontractorID'}
          label="Select Subcontractor"
          fullWidth={true}
          required={true}
          dropDownData={subcontractorData}
          dropDownShowMenu={'name'}
          dropDownValue={'ID'}
          onChange={e => {
            // loadTaxData();
            setValue(
              'nominatedSC',
              subcontractorData?.find(v => v?.ID === e?.target?.value)
                ?.nominatedSC,
            );
          }}
          disabled={disableCondition}
          errors={errors}
          defaultValue={editData?.subcontractorID}
        />
        <RegularTextController
          {...contConst}
          name={'subcontractNo'}
          className="left"
          label={'Subcontract No'}
          fullWidth={true}
          required={true}
          disabled={disableCondition}
          defaultValue={editData?.subcontractNo}
          errors={errors}
        />

        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Controller
            render={({ onChange, value }) => {
              return (
                <KeyboardDatePicker
                  label={'Subcontract Awarded Date'}
                  margin="normal"
                  className="right no-flow"
                  InputLabelProps={{
                    style: { whiteSpace: 'nowrap' },
                  }}
                  fullWidth
                  required
                  value={value}
                  disabled={disableCondition}
                  helperText={errors && errors['subcontractDate']?.message}
                  error={errors && errors['subcontractDate'] ? true : false}
                  format="dd MMM yyyy"
                  onChange={date => {}}
                  onAccept={date => {
                    let dateChange = changeDateFormat(date, 'DD MMM YYYY');
                    onChange(dateChange);
                  }}
                />
              );
            }}
            {...contConst}
            name={'subcontractDate'}
            defaultValue={changeDateFormat(
              mode === 'edit' || mode === 'resubmit'
                ? editData?.subcontractDate
                : new Date(),
              'DD MMM YYYY',
            )}
          />
        </MuiPickersUtilsProvider>

        <RegularTextController
          {...contConst}
          name={'title'}
          label="Title"
          fullWidth={true}
          required={true}
          multiline={true}
          defaultValue={editData?.title}
          errors={errors}
        />
        <RegularTextController
          {...contConst}
          name={'description'}
          label={'Description'}
          fullWidth={true}
          required={true}
          multiline={true}
          defaultValue={editData?.description}
          errors={errors}
        />

        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Controller
            render={({ onChange, value }) => {
              return (
                <KeyboardDatePicker
                  label="Start Date"
                  margin="normal"
                  className="left"
                  fullWidth
                  required
                  value={value}
                  disabled={disableCondition}
                  helperText={errors && errors['startDate']?.message}
                  error={errors && errors['startDate'] ? true : false}
                  format="dd MMM yyyy"
                  onChange={date => {}}
                  onAccept={date => {
                    let dateChange = changeDateFormat(date, 'DD MMM YYYY');
                    onChange(dateChange);
                  }}
                />
              );
            }}
            {...contConst}
            name="startDate"
            defaultValue={changeDateFormat(
              editData ? editData?.startDate : new Date(),
              'DD MMM YYYY',
            )}
          />
        </MuiPickersUtilsProvider>

        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Controller
            render={({ onChange, value }) => {
              return (
                <KeyboardDatePicker
                  label="End Date"
                  margin="normal"
                  className="left"
                  fullWidth
                  required
                  value={value}
                  disabled={disableCondition}
                  helperText={errors && errors['endDate']?.message}
                  error={errors && errors['endDate'] ? true : false}
                  format="dd MMM yyyy"
                  onChange={date => {}}
                  onAccept={date => {
                    let dateChange = changeDateFormat(date, 'DD MMM YYYY');
                    onChange(dateChange);
                  }}
                />
              );
            }}
            {...contConst}
            name="endDate"
            defaultValue={changeDateFormat(
              editData ? editData?.endDate : new Date(),
              'DD MMM YYYY',
            )}
          />
        </MuiPickersUtilsProvider>

        {!implementWbs && (
          <DropdownController
            {...contConst}
            name={'costCategoryID'}
            label="Trade Specification"
            fullWidth={true}
            required={true}
            dropDownData={getCostCategory?.filter(v => {
              return watch('subcontractorID')
                ? v?.subcontractor?.filter(
                    y => y?.ID === watch('subcontractorID'),
                  ).length > 0
                : true;
            })}
            dropDownShowMenu={'name'}
            dropDownValue={'ID'}
            disabled={disableCondition}
            errors={errors}
            defaultValue={editData?.costCategoryID}
          />
        )}

        {taxCondition() && (
          <DropdownController
            {...contConst}
            name={'taxSchemeID'}
            label="Tax"
            className="left"
            fullWidth={true}
            required={true}
            errors={errors}
            dropDownData={getConTaxScheme?.filter(
              v =>
                v?.commonStatus === CommonStatus.Active &&
                v?.taxClass === TaxClass.Input &&
                v?.taxEffective[0]?.taxRate >= 0,
            )}
            customDropDownShowMenu={(v: any) => {
              return (
                <div>
                  {v?.code} - {v?.description}
                </div>
              );
            }}
            dropDownValue={'ID'}
            dropDownShowValue={option => {
              let temp = getConTaxScheme?.find(x => x.ID === option);
              return `${temp?.code} | ${temp?.taxEffective[0]?.taxRate}%`;
            }}
            onChange={e => {
              setCurrTax({
                ...getConTaxScheme?.find(x => x.ID === e.target.value),
              });
            }}
            disabled={disableCondition}
            defaultValue={editData?.taxSchemeID}
          />
        )}
        <NumberFormatController
          {...contConst}
          name="creditTerm"
          label="Credit Term (Days) *"
          className="right"
          multiline={true}
          disabled={disableCondition}
          defaultValue={editData?.creditTerm}
          errors={errors}
        />

        <CheckBoxController
          {...contConst}
          name="nominatedSC"
          label={'Nominated Subcontract'}
          checked={
            editData?.nominatedSC ??
            subcontractorData?.find(v => v.ID === watch('subcontractorID'))
              ?.nominatedSC
          }
        />

        {bankChecked && (
          <>
            <DropdownController
              {...contConst}
              name={'bankAccountID'}
              label="Select Bank"
              fullWidth={true}
              required={true}
              errors={errors}
              dropDownData={getBankAccount}
              customDropDownShowMenu={(v: any) => {
                return (
                  <div className="content-wrap full">
                    <div>
                      <span className="xsTitle">
                        {v?.name} ({v?.branch})
                      </span>
                    </div>
                    <div className="desc">Account No: {v?.accountNo}</div>
                  </div>
                );
              }}
              dropDownValue={'ID'}
              dropDownShowValue={option => {
                let temp = getBankAccount?.find(x => x.ID === option);
                return `${temp?.name}  (${temp?.branch})`;
              }}
              // disabled={disableCondition}
              defaultValue={editData?.bankAccountID}
            />
            {/* <Controller
              as={TextField}
              name="swiftCode"
              control={control}
              id="standard-basic"
              label="Swift Code"
              fullWidth
              margin="normal"
              className="fullwidth"
              required
              error={errors?.swiftCode ? true : false}
              helperText={errors?.swiftCode?.message}
              autoComplete="off"
              defaultValue={editData?.swiftCode}
              ref={register}
            /> */}
          </>
        )}

        <FileUploadInput
          label="Attachment"
          files={files}
          onHandleUploadChange={handleUploadChange}
          multiple
          imagePreview={
            <>
              {previewFiles?.map((v, i) => (
                <UploadPreview
                  remove
                  key={v}
                  src={v}
                  onClick={() => removeFile(i)}
                  mediaType={
                    // files[i]?.type
                    files[i]?.type ?? DocumentListing[i]?.mediaType
                  }
                />
              ))}
            </>
          }
        />
      </CardContents>
    </>
  );
};

// to calculate the final amount with tax included (only after tax and customer has been selected)
const calcTotalAmtWithTax = (baseAmt: any, taxRate: any) => {
  let rateAmtInitial = new BigNumber(taxRate).div(100);

  let rateAmt =
    rateAmtInitial.toNumber() === 0 ? rateAmtInitial : rateAmtInitial.plus(1);

  let finalResult = new BigNumber(amtNumStr(baseAmt)).times(
    taxRate === 0 ? 1 : rateAmt,
  );

  return finalResult?.toNumber() || parseFloat(amtNumStr(baseAmt));
};
