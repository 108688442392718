import React, { lazy } from 'react';

const TenderInviteesListing = lazy(() =>
  import('./TenderInviteesListing').then(module => ({
    default: module.TenderInviteesListing,
  })),
);

const TenderInviteesSubmission = lazy(() =>
  import('./TenderInviteesSubmission').then(module => ({
    default: module.TenderInviteesSubmission,
  })),
);

const SubconTenderDetail = lazy(() =>
  import('./SubconTenderDetail').then(module => ({
    default: module.SubconTenderDetail,
  })),
);

const SubcontractTenderForm = lazy(() =>
  import('./SubcontractTenderForm').then(module => ({
    default: module.SubconTenderForm,
  })),
);

const SubconTenderInvitees = lazy(() =>
  import('./TenderInvite/SubconTenderInvitees').then(module => ({
    default: module.SubconTenderInvitees,
  })),
);

const SubconTenderEvalandSelec = lazy(() =>
  import('./SubconTenderEvalandSelec').then(module => ({
    default: module.SubconTenderEvalandSelec,
  })),
);

const SubconTenderInviteesSubmission = lazy(() =>
  import('./TenderInvite/SubconTenderInviteesSubmission').then(module => ({
    default: module.SubconTenderInviteesSubmission,
  })),
);

const SubconTenderInvite = lazy(() =>
  import('./TenderInvite/SubconTenderInvite').then(module => ({
    default: module.SubconTenderInvite,
  })),
);


const subconTenderRoutes = [
  // {
  //   props: {
  //     exact: true,
  //     path: `/tender/:subconTenderID/invitees`,
  //   },
  //   component: <TenderInviteesListing />,
  // },

  {
    props: {
      exact: true,
      path: `/home/:subconTenderID/tender-detail`,
    },
    component: <SubconTenderDetail />,
  },

  {
    props: {
      exact: true,
      path: `/home/:subconTenderID/tender-invitees`,
    },
    component: <TenderInviteesListing />,
  },

  {
    props: {
      exact: true,
      path: `/home/:subconTenderID/tender-invitees-submission`,
    },
    component: <TenderInviteesSubmission />,
  },

  {
    props: { exact: true, path: '/subcon-tender/:contractID/add' },
    component: <SubcontractTenderForm mode="add" />,
  },

  {
    props: {
      exact: true,
      path: '/home/:contractID/:subconTenderID/edit',
    },
    component: <SubcontractTenderForm mode="edit" />,
  },

  {
    props: {
      exact: true,
      path: '/subcon-tender/:contractID/:subconTenderID/tender-invitees',
    },
    component: <SubconTenderInvitees />,
  },

  {
    props: {
      exact: true,
      path: `/subcon-tender/:contractID/:subconTenderID/eval-and-select`,
    },
    component: <SubconTenderEvalandSelec />,
  },

  {
    props: {
      exact: true,
      path:
        '/subcon-tender/:contractID/:subconTenderID/tender-invitees/:subcontractor/tender-submission',
    },
    component: <SubconTenderInviteesSubmission />,
  },

  {
    props: {
      exact: true,
      path: '/subcon-tender/:contractID/:subconTenderID/tender-invitees/add',
    },
    component: <SubconTenderInvite />,
  },
];
export default subconTenderRoutes;
