import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import StandardTabs from '@ifca-root/react-component/src/components/Tabs/StandardTabs';
import AppContext from 'containers/App/Store/AppContext';
import { SubconTenderListing } from '../SubcontractTenderModule/SubconTenderListings';
import { SubcontractListing } from '../SubcontractModule/SubcontractListing';
import {
  useGetSubcontractLazyQuery,
  useGetSubcontractorLazyQuery,
  useGetSubcontractTenderQuery,
  useLoggedInPlatformUserProfileQuery,
} from 'generated/graphql';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';

export const SubcontractMain = props => {
  const { globalState, dispatch }: any = useContext(AppContext as any);
  const [change, setSwitch] = useState(false);
  const user = JSON.parse(localStorage.getItem('loggedInUser'));
  let history = useHistory();
  const [value, setValue] = useState(0);
  const [tenderCount, setTenderCount] = useState(0);
  const [subconCount, setSubconCount] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleDrawer = () => {
    dispatch({
      type: 'drawerOpen',
      payload: !globalState.drawerOpen,
    });
  };

  const {
    loading,
    error,
    data: { loggedInPlatformUserProfile } = {
      loggedInPlatformUserProfile: null,
    },
  } = useLoggedInPlatformUserProfileQuery();

  const [
    loadSubcontractor,
    {
      loading: subconLoading,
      error: subconError,
      data: { getSubcontractor } = { getSubcontractor: [] },
    },
  ] = useGetSubcontractorLazyQuery({
    fetchPolicy: 'network-only',
  });

  const {
    loading: subconTenderLoading,
    error: subconTenderError,
    data: { getSubcontractTender } = { getSubcontractTender: [] },
  } = useGetSubcontractTenderQuery({
    fetchPolicy: 'network-only',
    onCompleted: ({ getSubcontractTender }) => {
      setTenderCount(getSubcontractTender?.length);
    },
  });

  const [
    loadSubcontract,
    {
      loading: subcontractLoading,
      error: subcontractError,
      data: { getSubcontract } = { getSubcontract: [] },
    },
  ] = useGetSubcontractLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: ({ getSubcontract }) => {
      setSubconCount(getSubcontract?.length);
    },
  });

  let userAccDetail;
  userAccDetail = {
    companyName: getSubcontractor[0]?.name,
    name: loggedInPlatformUserProfile?.contactDetail?.name,
    ...getSubcontractor,
    ...loggedInPlatformUserProfile!,
  };

  // useEffect..................

  useEffect(() => {
    if (!!loggedInPlatformUserProfile?.associateID) {
      loadSubcontractor({
        variables: { ID: loggedInPlatformUserProfile?.associateID },
      });
      loadSubcontract({
        variables: {
          subcontractorID: loggedInPlatformUserProfile?.associateID,
        },
      });
    }
  }, [loggedInPlatformUserProfile]);

  // user info
  localStorage.setItem('loggedInUser', JSON.stringify(userAccDetail));

  return (
    <>
      {loading && <Loading />}
      {subconTenderLoading && <Loading />}
      {subcontractLoading && <Loading />}
      <MainHeader
        mainBtn="menu"
        smTitle="Subcontractor App"
        title={user?.companyName}
        routeSegments={[{ name: 'Main Menu', current: true }]}
        onClickAction={() => setSwitch(true)}
      />
      <StandardTabs
        value={value}
        onChange={handleChange}
        responsive
        fullWidth
        tabs={[
          {
            label: `Subcon Tender (${tenderCount})`,
            content: (
              <SubconTenderListing
                getSubcontractTender={getSubcontractTender}
                setToDoCount={setTenderCount}
              />
            ),
          },
          {
            label: `Subcontract (${subconCount})`,
            content: (
              <SubcontractListing
                getSubcontract={getSubcontract}
                setNotiCount={setSubconCount}
              />
            ),
          },
        ]}
      />
    </>
  );
};
