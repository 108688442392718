import { Checkbox, FormControl, FormControlLabel } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { ICheckBoxControllerProps } from './ConSubconInterface';

const CheckBoxController = (props: ICheckBoxControllerProps) => {
  const {
    name,
    register,
    control,
    checked,
    handleCheckBoxChange,
    label,
    defaultChecked,
  } = props;

  console.log(checked, 'checked huh');
  const [check, setCheck] = useState<boolean>(false);

  useEffect(() => {
    setCheck(checked);
  }, [checked]);

  return (
    <>
      <FormControl component="fieldset">
        <Controller
          as={
            <FormControlLabel
              control={
                <Checkbox
                  checked={check}
                  onChange={
                    !!handleCheckBoxChange
                      ? handleCheckBoxChange
                      : (e: any) => {
                          setCheck(e.target.checked);
                        }
                  }
                  name={name}
                  color="primary"
                />
              }
              label={label}
            />
          }
          name={name}
          control={control}
          inputRef={register()}
        />
      </FormControl>
    </>
  );
};

export default CheckBoxController;
