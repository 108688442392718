import {
  IContactObjProps,
  IDialogProps,
} from 'containers/SubcontractModule/ConSubconHelper/ConSubconInterface';
import { yupResolver } from '@hookform/resolvers';
import { contactYupValidation } from 'containers/SubcontractModule/ConSubconHelper/ConSubconYupObject';
import { Controller, useForm } from 'react-hook-form';
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog';
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents';
import React from 'react';
import { TextField } from '@material-ui/core';
import { PhoneNumInput } from '@ifca-root/react-component/src/components/Input/PhoneNumInput';
import { formatNo } from '@contract-root/admin-react/src/helpers/Hooks/formatContactNo';

export const SubconContactForm = (props: IDialogProps) => {
  const {
    setOpenDialog,
    openDialog,
    menu,
    isEdit,
    setValue: setMainVal,
    value,
  } = props;
  const { register, control, handleSubmit, watch, setValue, errors } = useForm<
    IContactObjProps
  >({
    defaultValues: {},
    mode: 'onSubmit',
    resolver: yupResolver(contactYupValidation()),
  });

  const onSubmitContact = data => {
    let tempContNo = formatNo(data?.contactNo);
    data.contactNo = tempContNo;
    if (isEdit) {
      let temp = value;
      temp[menu?.index] = data;
      setMainVal([...temp]);
    } else {
      setMainVal([...value, data]);
    }
    setOpenDialog(false);
  };

  const user = JSON.parse(localStorage.getItem('loggedInUser'));

  return (
    <>
      <CommonDialog
        fullWidth
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        sections={{
          header: {
            title: 'Contact',
            rightText: isEdit ? 'Edit' : 'Add',
          },
          body: () => (
            <CardContents>
              <Controller
                as={TextField}
                name="name"
                label="Name *"
                //required
                autoComplete="off"
                control={control}
                multiline={true}
                fullWidth
                margin="normal"
                helperText={errors && errors?.name?.message}
                error={errors && errors?.name ? true : false}
                ref={register}
                defaultValue={menu?.obj?.name || ''}
              />
              <Controller
                as={TextField}
                name="designation"
                label="Designation *"
                autoComplete="off"
                control={control}
                multiline={true}
                fullWidth
                ref={register}
                helperText={errors && errors?.designation?.message}
                error={errors && errors?.designation ? true : false}
                defaultValue={menu?.obj?.designation || ''}
              />

              <Controller
                as={TextField}
                name="email"
                label="Email Address"
                autoComplete="off"
                control={control}
                multiline={true}
                fullWidth
                helperText={errors && errors?.email?.message}
                error={errors && errors?.email ? true : false}
                ref={register}
                defaultValue={menu?.obj?.email || ''}
              />

              <Controller
                as={
                  <PhoneNumInput
                    country={user?.companyCountryCode}
                    specialLabel="Mobile No. *"
                  />
                }
                name="contactNo"
                required
                fullWidth
                marginTop
                marginBottom
                id="standard-basic"
                type="tel"
                autoComplete="off"
                control={control}
                helperText={errors && errors?.contactNo?.message}
                error={errors && errors?.contactNo ? true : false}
                ref={register}
                // className="right"
                defaultValue={menu?.obj?.contactNo || ''}
              />
            </CardContents>
          ),
          footer: {
            actions: [
              {
                displayText: 'Cancel',
                props: {
                  onClick: () => {
                    setOpenDialog(false);
                  },
                  variant: 'contained',
                  color: 'primary',
                },
              },
              {
                displayText: 'Confirm',
                props: {
                  onClick: () => {
                    handleSubmit(onSubmitContact)();
                  },
                  variant: 'contained',
                  color: 'primary',
                },
              },
            ],
          },
        }}
      />
    </>
  );
};
