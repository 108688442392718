import { amtNumStr } from '@contract-root/admin-react/src/helpers/StringNumberFunction/numFormatter';
import { CommonStatus, ContractStatus } from 'generated/graphql';

export const GeneralConSubconInput = (data, files) => {
  console.log(data, 'CONSUBCON DATA');
  return {
    commonStatus: data?.commonStatus || CommonStatus.Active,
    title: data?.title,
    description: data?.description,
    taxSchemeID: data?.taxSchemeID,
    startDate: data?.startDate,
    endDate: data?.endDate,
    creditTerm: numberReturnValue(data?.creditTerm),
    file: files,
  };
};

export const SubconTradeExceedInput = (data, subconTradeFiles) => {
  return {
    submissionComment: data?.submissionComment,
    SubconTradeFile: subconTradeFiles,
  };
};

export const SubconTradeInput = data => {
  return {
    wbsID: data?.wbsID,
    taxSchemeID: data?.taxSchemeID,
    costCategoryID: data?.costCategoryID,
    tradeAmt: data?.tradeAmt,
    creditTerm: data?.creditTerm,
    budgetAmt: data?.budgetAmt,
  };
};

export const ContractInput = data => {
  return {
    customerID: data?.customerID,
    contractNo: data?.contractNo,
    contractDate: data?.contractDate,
    // contractDate: new Date(data?.contractDate),
    contractSum: numberReturnValue(data?.contractSum),
    contractStatus: ContractStatus.Active,
    implementWbs: data?.implementWbs,
  };
};

export const SubcontractInput = data => {
  return {
    subcontractNo: data?.subcontractNo,
    subcontractDate: data?.subcontractDate,
    subcontractSum: numberReturnValue(data?.subcontractSum),
    contractID: data?.contractID,
    siteID: data?.siteID,
    subcontractorID: data?.subcontractorID,
    costCategoryID: data?.costCategoryID,
    nominatedSC: data?.nominatedSC,
  };
};

export const LADInput = (data, LADFiles) => {
  return {
    ladAmt: data?.LADAmt ? numberReturnValue(data?.LADAmt) : data?.ladAmt,
    ladRate: data?.LADRate ? data?.LADRate : data?.ladRate,
    LADFile: LADFiles,
  };
};

export const RetentionInput = data => {
  return {
    progressRetentionPerc: numberReturnValue(data?.progressRetentionPerc),
    maxRetentionPerc: numberReturnValue(data?.maxRetentionPerc),
  };
};

export const OldBankDetailsInput = data => {
  return {
    bankDetails: {
      name: data?.bankName,
      acctHolder: data?.accHolderName,
      acctNo: data?.bankAccNo,
      swiftCode: data?.swiftCode,
    },
  };
};

export const BankDetailsInput = data => {
  return {
    bankAccountID: data?.bankAccountID,
    // swiftCode: data?.swiftCode,
  };
};

export const DocNumInput = data => {
  return {
    claimRunNum: data?.claimRunNum
      ? numberReturnValue(data?.claimRunNum)
      : data?.latestRunNum?.claimRunNum,
    voRunNum: data?.voRunNum
      ? numberReturnValue(data?.voRunNum)
      : data?.latestRunNum?.voRunNum,
    claimPrefix: data?.claimPrefix
      ? data?.claimPrefix
      : data?.latestRunNum?.claimPrefix,
    voPrefix: data?.voPrefix ? data?.voPrefix : data?.latestRunNum?.voPrefix,
  };
};

export const ContactInput = data => {
  return data?.map(v => {
    return {
      ID: v?.ID,
      name: v?.name,
      email: v?.email,
      designation: v?.designation,
      contactNo: v?.contactNo,
      associateID: v?.associateID,
    };
  });
};

export const AddressInput = data => {
  return data?.map(v => {
    return {
      ID: v?.ID,
      siteName: v?.name || 'N/A',
      mainSite: v?.mainSite || false,
      address: v?.address?.address,
      city: v?.address?.city,
      state: v?.address?.state,
      postCode: v?.address?.postCode,
      country: v?.address?.country,
    };
  });
};

export const ReconsAddressInput = data => {
  return data?.map(v => {
    return {
      ID: v?.ID || undefined,
      name: v?.siteName || 'N/A',
      mainSite: v?.mainSite || false,
      address: {
        address: v?.address,
        city: v?.city,
        state: v?.state,
        postCode: v?.postCode,
        country: v?.country,
      },
    };
  });
};

export const numberReturnValue = data => {
  if (typeof data === 'string') {
    return parseFloat(amtNumStr(data));
  } else if (typeof data === 'number') {
    return data;
  }
};

export const defaultVals = (
  mode,
  conSubcon: 'contract' | 'subcontract',
  data,
) => {
  if (data) {
    const retVal = name => {
      if (mode === 'edit' || mode === 'resubmit') {
        return data[name];
      } else {
        return null;
      }
    };

    const retKey = (isNeededSubcon, name) => {
      if (isNeededSubcon) {
        return { [name]: retVal(name) };
      } else {
        return null;
      }
    };

    const isSub = conSubcon === 'subcontract';
    const isCon = conSubcon === 'contract';

    return {
      ...retKey(isSub, 'contractID'),
      ...retKey(isCon, 'contractNo'),
      ...retKey(isSub, 'subcontractNo'),
      ...retKey(isCon, 'contractDate'),
      ...retKey(isSub, 'subcontractDate'),
      title: data?.title,
      description: data?.description,
      startDate: data?.startDate,
      endDate: data?.endDate,
      ...retKey(isSub, 'subcontractorID'),
      taxSchemeID: data?.taxSchemeID,
      creditTerm: data?.creditTerm,
      ...retKey(isCon, 'customerID'),
      ...retKey(isSub, 'costCategoryID'),
      ...retKey(isSub, 'nominatedSC'),
      ...retKey(isSub, 'siteID'),
      LADAmt: data?.ladAmt,
      LADRate: data?.ladRate,
      progressRetentionPerc: data?.progressRetentionPerc,
      maxRetentionPerc: data?.maxRetentionPerc,
      ...retKey(isSub, 'subcontractSum'),
      ...retKey(isCon, 'contractSum'),
    };
  }
};

interface IInputData {
  mode: 'add' | 'edit' | 'resubmit';
  func: any;
  data1: any;
  extraFiles?: any;
  data2?: any;
  isCurrEditType?: boolean;
}

// data1 -- from useForm
// data2 -- from editData
export const inputData = (data: IInputData) => {
  const { mode, func, data1, data2, extraFiles, isCurrEditType } = data;

  if (mode === 'add') {
    return extraFiles ? func(data1, extraFiles) : func(data1);
  } else if (mode === 'edit' || mode === 'resubmit') {
    if (isCurrEditType) {
      return extraFiles ? func(data1, extraFiles) : func(data1);
    } else {
      return extraFiles ? func(data2, extraFiles) : func(data2);
    }
  }
};
