import { BroadcastChannel } from 'broadcast-channel'
import { useState } from 'react'

declare type Message = {
  userId: string
  payload: any
}

export const useBroadcastChannel = () => {
  // const [userChannel, setUSerChannel] = useState(new BroadcastChannel('user'));
  const [userChannel, setUSerChannel] = useState<BroadcastChannel<Message>>(
    new BroadcastChannel('user')
  )

  const signOut = (userId: string) => {
    // and after that, we have to broadcast a message to the user channel which user has signed out from the application as below:
    userChannel.postMessage({
      userId: userId, // If the user opened your app in multi-tabs and signed-in with multi accounts, you need to put the userId here to identify which account has signed out exactly
      payload: {
        type: 'SIGN_OUT',
      },
    })
  }

  return {
    userChannel,
    signOut,
  }
}
