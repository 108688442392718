import React, { useContext, useEffect, useState } from 'react';
import { SubconContext } from '../SubconContext';
import { IContactObjProps } from 'containers/SubcontractModule/ConSubconHelper/ConSubconInterface';
import { useGetCollectionActivityQuery } from 'generated/graphql';
import { useMenuOption } from '@contract-root/admin-react/src/helpers/Hooks/useMenuOption';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents';
import { AddCircle, MoreVert } from '@material-ui/icons';
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList';
import {
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  Menu,
  MenuItem,
} from '@material-ui/core';
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog';
import TextSeparator from '@ifca-root/react-component/src/components/Typography/TextSeparator';
import { SubconContactForm } from './SubconContactForm';

export const SubconContactList = () => {
  const {
    // conSubcon,
    register,
    setValue,
    watch,
    errors,
    subcontractID,
    contractData,
    subcontractorData,
    subcontractData,
    defaultContact,
    mode,
  } = useContext(SubconContext);

  const [contacts, setContacts] = useState<IContactObjProps[]>(
    defaultContact ?? [],
  );
  useEffect(() => {
    if (defaultContact?.length > 0) {
      setContacts(defaultContact);
    }
  }, [defaultContact]);

  const {
    anchorEl,
    menu,
    handleClick,
    handleClose,
    resetMenu,
  } = useMenuOption();

  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openDeleteDialog, setDeleteDialog] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [error, setError] = useState(false);

  const {
    loading,
    data: { getCollectionActivity } = { getCollectionActivity: [] },
  } = useGetCollectionActivityQuery({
    fetchPolicy: 'network-only',
    variables: {
      contractID: subcontractID,
    },
  });

  useEffect(() => {
    register('contacts');
    setValue('contacts', contacts);
  }, [contacts]);

  useEffect(() => {
    if (watch('subcontractorID')) {
      let currContactData;
      if (mode === 'add') {
        currContactData = subcontractorData?.find(
          x => x?.ID === watch('subcontractorID'),
        )?.contactPerson;
      } else {
        currContactData = defaultContact;
      }

      setContacts(currContactData ? [...currContactData] : []);
      //// if one day they can add new contacts use this =>
      //// whether user add the contact first or later
      // setContacts([...contacts, currContactData?.contactPerson])
    }
  }, [watch('subcontractorID')]);

  return (
    <>
      {loading && <Loading />}
      <CardContents
        section={{
          header: {
            title: `Contact Details (${contacts ? contacts?.length : 0})`,
            onClickAction: () => {
              setOpenDialog(true);
              setIsEdit(false);
              resetMenu();
            },
            icon: <AddCircle />,
          },
        }}
      >
        {!contacts || contacts?.length === 0 ? (
          <div
            style={{
              width: '100%',
              justifyContent: 'center',
            }}
          >
            <EmptyList
              title={
                errors?.contacts?.message
                  ? 'Require atleast one contacts'
                  : 'No Contact Record(s) found'
              }
              subtitle={'Please choose contract to view contact list available'}
              errorMessage={errors?.contacts?.message}
            />
          </div>
        ) : (
          contacts?.map((el, index) => {
            return (
              <>
                <List className="borderless-list content-wrap full text-noflow">
                  <ListItem divider>
                    <div className="content-wrap full">
                      <div className="desc">
                        <span className="xsTitle"> {el?.name} </span> (
                        {el?.designation})
                      </div>
                      <div className="desc">
                        <u style={{ color: '#5367FF' }}>{el?.contactNo}</u>
                        <span>&nbsp;|&nbsp;</span>
                        <u style={{ color: '#5367FF' }}>{el?.email || 'N/A'}</u>
                      </div>
                    </div>

                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        aria-label="more"
                        aria-controls="menu-list"
                        aria-haspopup="true"
                        onClick={e => handleClick(e, el?.name, index, el)}
                      >
                        <MoreVert />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                </List>
              </>
            );
          })
        )}
      </CardContents>

      <Menu
        id="menu-list"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={handleClose}
      >
        <MenuItem
          onClick={() => {
            setOpenDialog(true);
            setIsEdit(true);
          }}
        >
          <span>Edit</span>
        </MenuItem>
        <MenuItem
          disabled={contacts?.length === 1}
          onClick={() => setDeleteDialog(true)}
        >
          <span>Delete</span>
        </MenuItem>
      </Menu>

      <SubconContactForm
        mode={isEdit ? 'edit' : 'add'}
        menu={menu}
        setOpenDialog={setOpenDialog}
        openDialog={openDialog}
        isEdit={isEdit}
        setValue={setContacts}
        value={contacts}
      />

      <CommonDialog
        fullWidth
        hidden={error}
        open={openDeleteDialog}
        onClose={() => {
          setDeleteDialog(false);
          setError(false);
        }}
        sections={{
          header: {
            title: `Contact`,
            rightText: 'Delete',
          },
          body: () => (
            <div className="content-wrap full">
              <div className="desc">
                <span className="mdDesc full">
                  Are you sure you want to delete this contact?
                </span>
              </div>
              <br />
              <div className="desc">
                <span className="xsTitle">{menu?.obj?.name}</span> (
                {menu?.obj?.designation})
              </div>
              <div className="desc">
                <a href={`tel:${menu?.obj?.contactNo}`}>
                  {menu?.obj?.contactNo}
                </a>
                <TextSeparator />
                <a href={`mailto:${menu?.obj?.email}`}>{menu?.obj?.email}</a>
              </div>
            </div>
          ),
          footer: {
            actions: [
              {
                displayText: 'Cancel',
                props: {
                  onClick: () => {
                    setDeleteDialog(false);
                    setError(false);
                  },
                  variant: 'contained',
                  color: 'primary',
                },
              },
              {
                displayText: 'Confirm',
                props: {
                  onClick: () => {
                    console.log('excuse me?');
                    let checkFK = getCollectionActivity?.find(
                      v => v?.contactID === menu?.obj?.ID,
                    );

                    console.log(checkFK, ' masil tak ');

                    if (!checkFK) {
                      setDeleteDialog(false);
                      let temp = contacts;
                      temp?.splice(menu?.index, 1);
                      setContacts([...temp]);
                    } else {
                      setError(true);
                    }
                  },
                  variant: 'contained',
                  color: 'primary',
                },
              },
            ],
          },
        }}
      />

      <CommonDialog
        fullWidth
        open={error}
        onClose={() => {
          setDeleteDialog(false);
          setError(false);
        }}
        sections={{
          header: {
            title: `Error Deleting Contact`,
          },
          body: () => (
            <div className="mdDesc">{`Contact ${menu?.obj?.name} is in used!`}</div>
          ),
          footer: {
            actions: [
              {
                displayText: 'Exit',
                props: {
                  onClick: () => {
                    setError(false);
                    setDeleteDialog(false);
                  },
                  variant: 'contained',
                  color: 'primary',
                },
              },
            ],
          },
        }}
      />
    </>
  );
};
