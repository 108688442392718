import { TextField } from '@material-ui/core';
import React from 'react';
import { Controller } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { INumberFormatControllerProps } from './ConSubconInterface';

const NumberFormatController = (props: INumberFormatControllerProps) => {
  const {
    name,
    control,
    register,
    label,
    className,
    value: valueProvided,
    errors,
    defaultValue,
    disabled,
    decimalScale,
    allowNegative,
    multiline,
    onChange: onChangeControlled,
    max,
    min,
    helperText,
  } = props;

  const errorKey = name;

  return (
    <>
      <Controller
        ref={register()}
        name={name}
        control={control}
        defaultValue={defaultValue}
        onChangeName="onValueChange"
        // render={({ onChange, onBlur, value }) => (
        onChange={([{ value, floatValue }]) => {
          // if(floatvalue === 0.00)

          return value;
        }}
        as={
          <NumberFormat
            thousandSeparator
            allowNegative={allowNegative || false}
            customInput={TextField}
            multiline={multiline || true}
            disabled={disabled}
            // isNumericString
            // defaultValue={defaultValue}
            margin="normal"
            // required
            max={max}
            min={min}
            autoComplete="off"
            className={className}
            label={label}
            name={name}
            decimalScale={decimalScale}
            onValueChange={val => {
              if (!!onChangeControlled) onChangeControlled(val);
              // onChange(val);
            }}
            value={valueProvided}
            helperText={
              !!helperText ? helperText : errors && errors[errorKey]?.message
            }
            error={
              !!helperText ? true : errors && errors[errorKey] ? true : false
            }
            fixedDecimalScale
          />
          // )}
        }
      />
    </>
  );
};

export default NumberFormatController;
