import { TextField } from '@material-ui/core'
import React, { useState } from 'react'
import PhoneInput from 'react-phone-input-2'
import { isValidPhoneNumber } from 'libphonenumber-js'
import './phoneInput.scss'
// interface PhoneNumProps {
//   fullWidth?: string
//   props: any
//   inputProps: any
// }

export const PhoneNumInput = props => {
  const { fullWidth, helperText, error, ...rest } = props
  const [isTrue, setIsTrue] = useState(false)
  return (
    <>
      <div style={{ paddingBottom: '10px ', width: '100%' }}>
        <PhoneInput
          i
          {...rest}
          containerClass="test"
          inputStyle={{
            border: 'none',
            borderBottom: '.1px solid darkgrey',
            outline: 'none',
            borderRadius: 0,
          }}
          isValid={(value, country: any) => {
            let countryCode = country.iso2
            let isPhoneNumber = isValidPhoneNumber(
              value,
              countryCode?.toUpperCase()
            )

            if (value?.slice(country?.dialCode?.length)) {
              if (!isPhoneNumber) {
                setIsTrue(true)
              } else {
                setIsTrue(false)
              }
            } else {
              setIsTrue(false)
              return true
            }
          }}
          style={{ width: fullWidth ? '100%' : null, zIndex: 2 }}
          //   inputStyle={{
          //     width: '50%',
          //   }}
          //   inputProps={
          //     {
          //       // style: { width: fullWidth ? '100%' : null },
          //       // className: 'phone-no-input',
          //     }
          //   }
          inputProps={{
            style: {
              borderColor: error || isTrue ? '#E53935' : null,
            },
          }}
        />
        {(error || isTrue) && (
          <TextField
            InputProps={{ disableUnderline: true }}
            disabled
            variant="standard"
            helperText={isTrue ? 'Invalid Phone Number' : helperText}
            error={isTrue || error}
            style={{ marginTop: '-32px' }}
          />
        )}
      </div>
    </>
  )
}
