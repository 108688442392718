import { TextField } from '@material-ui/core';
import React from 'react';
import { Controller } from 'react-hook-form';
import { IRegularTextControllerProps } from './ConSubconInterface';

const RegularTextController = (props: IRegularTextControllerProps) => {
  const {
    errors,
    control,
    register,
    label,
    name,
    disabled,
    className,
    required,
    fullWidth,
    multiline,
    onChange,
    variant,
    value,
    defaultValue,
  } = props;

  const errorKey = name;

  return (
    <>
      <Controller
        as={TextField}
        fullWidth={fullWidth}
        helperText={errors && errors[errorKey]?.message}
        error={errors && errors[errorKey] ? true : false}
        autoComplete="off"
        onChange={onChange}
        required={required}
        multiline={multiline}
        variant={variant}
        className={className}
        margin="normal"
        disabled={disabled}
        name={name}
        label={label}
        control={control}
        ref={register}
        defaultValue={defaultValue}
        value={value}
      />
    </>
  );
};

export default RegularTextController;
