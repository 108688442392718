import {
  IAddressParentProps,
  IDialogProps,
} from 'containers/SubcontractModule/ConSubconHelper/ConSubconInterface';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers';
import { addressYupValidation } from 'containers/SubcontractModule/ConSubconHelper/ConSubconYupObject';
import { useQuery } from '@apollo/react-hooks';
import { COUNTRY } from '@contract-root/admin-react/src/containers/AccountModule/GraphQL/AccountQuery';
import { AccountClient } from '@contract-root/admin-react/src/IFCAXClient';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog';
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents';
import { Controller, useForm } from 'react-hook-form';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  MenuItem,
  TextField,
} from '@material-ui/core';

export const SubconAddressForm = (props: IDialogProps) => {
  const {
    setOpenDialog,
    openDialog,
    menu,
    setValue: setMainVal,
    value,
    isEdit,
  } = props;

  const [mainSiteSetup, setMainSiteSetup] = useState(false);
  const { register, control, handleSubmit, watch, setValue, errors } = useForm<
    IAddressParentProps
  >({
    defaultValues: {},
    mode: 'onSubmit',
    resolver: yupResolver(addressYupValidation()),
  });

  const {
    loading,
    error,
    data: { getCountry } = { getCountry: [] },
  } = useQuery(COUNTRY, {
    client: AccountClient,
    variables: { orderByAsc: 'name' },
  });

  const onSubmitAddress = data => {
    if (isEdit) {
      let temp = value;
      if (mainSiteSetup) {
        temp = temp?.map(v => {
          return {
            ...v,
            mainSite: false,
          };
        });
      }

      temp[menu?.index] = { ...data, ID: menu?.ID };
      setMainVal([...temp]);
    } else {
      let temp = value;
      if (mainSiteSetup) {
        temp = temp?.map(v => {
          return {
            ...v,
            mainSite: false,
          };
        });
      }

      setMainVal([...temp, data]);
    }
    setOpenDialog(false);
  };

  useEffect(() => {
    setMainSiteSetup(menu?.obj?.mainSite);
  }, [menu?.obj]);

  const handleMainSiteChange = (event: ChangeEvent<HTMLInputElement>) => {
    setMainSiteSetup(event.target.checked);
  };

  return (
    <>
      {loading && <Loading />}
      <CommonDialog
        fullWidth
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        sections={{
          header: {
            title: `Project Address`,
            rightText: isEdit ? 'Edit' : 'Add',
          },
          body: () => (
            <CardContents>
              <Controller
                as={TextField}
                name="siteName"
                label="Site Name"
                required
                autoComplete="off"
                control={control}
                multiline={true}
                fullWidth
                helperText={errors?.siteName?.message}
                error={errors?.siteName ? true : false}
                ref={register}
                defaultValue={menu?.obj?.siteName || ''}
              />
              <Controller
                as={TextField}
                name="address"
                label="Address"
                required
                autoComplete="off"
                control={control}
                multiline={true}
                fullWidth
                helperText={errors?.address?.message}
                error={errors?.address ? true : false}
                ref={register}
                defaultValue={menu?.obj?.address || ''}
              />
              <Controller
                as={TextField}
                name="city"
                label="City"
                autoComplete="off"
                control={control}
                multiline={true}
                fullWidth
                helperText={errors?.city?.message}
                error={errors?.city ? true : false}
                ref={register}
                className="left"
                defaultValue={menu?.obj?.city || ''}
              />
              <Controller
                as={TextField}
                name="state"
                label="State"
                autoComplete="off"
                control={control}
                multiline={true}
                fullWidth
                helperText={errors?.state?.message}
                error={errors?.state ? true : false}
                ref={register}
                className="right"
                defaultValue={menu?.obj?.state || ''}
              />
              <Controller
                as={TextField}
                name="postCode"
                label="Postcode"
                required
                autoComplete="off"
                control={control}
                multiline={true}
                fullWidth
                helperText={errors?.postCode?.message}
                error={errors?.postCode ? true : false}
                ref={register}
                className="left"
                defaultValue={menu?.obj?.postCode || ''}
              />
              <Controller
                as={
                  <TextField select>
                    {getCountry?.map(v => (
                      <MenuItem key={v?.ID} value={v?.name}>
                        {v?.name}
                      </MenuItem>
                    ))}
                  </TextField>
                }
                name="country"
                label="Country"
                required
                autoComplete="off"
                control={control}
                multiline={true}
                fullWidth
                helperText={errors?.country?.message}
                error={errors?.country ? true : false}
                ref={register}
                className="right"
                defaultValue={menu?.obj?.country || ''}
              />
              <FormControl component="fieldset">
                <Controller
                  as={
                    <FormControlLabel
                      control={
                        <Checkbox
                          // defaultChecked={menu?.obj?.mainSite}
                          checked={mainSiteSetup}
                          onChange={handleMainSiteChange}
                          name="mainSite"
                          color="primary"
                        />
                      }
                      label="Main Site"
                    />
                  }
                  name="mainSite"
                  inputRef={register()}
                  control={control}
                />
              </FormControl>
            </CardContents>
          ),
          footer: {
            actions: [
              {
                displayText: 'Cancel',
                props: {
                  onClick: () => {
                    setOpenDialog(false);
                  },
                  variant: 'contained',
                  color: 'primary',
                },
              },
              {
                displayText: 'Confirm',
                props: {
                  onClick: () => {
                    handleSubmit(onSubmitAddress)();
                  },
                  variant: 'contained',
                  color: 'primary',
                },
              },
            ],
          },
        }}
      />
    </>
  );
};
