import { MenuItem, TextField } from '@material-ui/core';
import React from 'react';
import { Controller } from 'react-hook-form';
import { IDropDownControllerProps } from './ConSubconInterface';

const DropdownController = (props: IDropDownControllerProps) => {
  const {
    errors,
    control,
    register,
    label,
    name,
    className,
    disabled,
    required,
    defaultValue,
    fullWidth,
    dropDownShowValue,
    dropDownData,
    dropDownValue,
    dropDownShowMenu,
    onChange: onChangeControlled,
    customDropDownShowMenu,
    value,
  } = props;

  const errorKey = name;

  return (
    <Controller
      render={({ onChange, onBlur, value }) => (
        <TextField
          margin="normal"
          helperText={errors && errors[errorKey]?.message}
          error={errors && errors[errorKey] ? true : false}
          label={label}
          required={required}
          select
          className={className}
          disabled={disabled}
          defaultValue={defaultValue}
          value={value}
          SelectProps={{
            renderValue: dropDownShowValue,
          }}
          onChange={e => {
            if (!!onChangeControlled) onChangeControlled(e);
            onChange(e);
          }}
        >
          {dropDownData?.map((el, index) => (
            <MenuItem value={el[dropDownValue]} key={index}>
              {!!customDropDownShowMenu ? (
                customDropDownShowMenu(el, index)
              ) : (
                <span>{el[dropDownShowMenu]}</span>
              )}
            </MenuItem>
          ))}
        </TextField>
      )}
      name={name}
      select
      autoComplete="off"
      control={control}
      fullWidth={fullWidth}
      ref={register}
      defaultValue={defaultValue}
      value={value}
    />
  );
};

export default DropdownController;
