import { SystemMsgs } from '@contract-root/admin-react/src/helpers/Messages/SystemMsg';
import { amtNumStr } from '@contract-root/admin-react/src/helpers/StringNumberFunction/numFormatter';
import { CommonYupValidation } from '@contract-root/admin-react/src/helpers/YupSchema/yup';
import {
  dateComparison,
  dateConSubConComparison,
} from '@ifca-root/react-component/src/helpers/StringNumberFunction/formatDate';
import BigNumber from 'bignumber.js';
import * as yup from 'yup';

export const defaultYupValidation = (
  conSubcon: 'contract' | 'subcontract',
  dateData: { startDate: string; endDate: string; projDate: string },
  contractSum?: number,
  contractDate?: any,
  contractStartEnd?: any,
  wbsAccess?: boolean,
) => {
  // console.log(conSubcon, wbsAccess, 'yupSchema');

  return {
    description: CommonYupValidation.requireField(SystemMsgs.description()),
    startDate: yup
      .string()
      .required(SystemMsgs.dateSelection())
      // .test(
      //   'startTest',
      //   `Start Date must be later than ${
      //     conSubcon === 'contract' ? 'Contract' : 'Subcontract'
      //   } Date`,
      //   val => {
      //     if (dateData?.projDate) {
      //       console.log(
      //         val >= new Date(dateData?.projDate),
      //         'val',
      //         val,
      //         new Date(dateData?.projDate),
      //       );
      //       //   return false;
      //       // } else return false;
      //       return !!val && new Date(val) >= new Date(dateData?.projDate);
      //     } else return !!val && new Date(val) >= new Date();
      //   },
      // )
      .test(
        'endTest',
        `Start Date must be later than ${
          conSubcon === 'contract' ? 'Contract' : 'Subcontract'
        }
      `,
        val => {
          if (dateData?.projDate)
            return !!val && dateComparison(val, dateData?.projDate, 'gte');
          else return !!val && dateComparison(val, new Date(), 'gte');
          // if (dateData?.endDate)
          //   return (
          //     !!val &&
          //     val?.toLocaleDateString() <=
          //       new Date(dateData?.endDate).toLocaleDateString()
          //   );
          // else
          //   return (
          //     !!val &&
          //     val?.toLocaleDateString() <= new Date().toLocaleDateString()
          //   );
        },
      ),
    endDate: yup
      .string()
      .required(SystemMsgs.dateSelection())
      .test('startEndTest', 'End Date must be later than Start Date', val => {
        return !!val && dateComparison(val, dateData?.startDate, 'gt');
      }),
    creditTerm: CommonYupValidation.requireField(SystemMsgs.creditTerm()),
    taxSchemeID: CommonYupValidation.requireField(SystemMsgs.taxType()),
    contractID:
      conSubcon == 'subcontract'
        ? CommonYupValidation.requireField(SystemMsgs.maincontract())
        : null,
    customerID:
      conSubcon == 'contract'
        ? CommonYupValidation.requireField(SystemMsgs.client())
        : null,
    subcontractorID:
      conSubcon == 'subcontract'
        ? CommonYupValidation.requireField(SystemMsgs.client())
        : null,
    contractNo:
      conSubcon == 'contract'
        ? CommonYupValidation.requireField(SystemMsgs.contractNo())
        : null,
    subcontractNo:
      conSubcon == 'subcontract'
        ? CommonYupValidation.requireField(SystemMsgs.subcontractNo())
        : null,
    contractDate:
      conSubcon == 'contract'
        ? CommonYupValidation.requireField(SystemMsgs.dateSelection())
        : null,
    subcontractDate:
      conSubcon == 'subcontract'
        ? yup
            .string()
            .required('Subcontract Date is required')
            // .test(
            //   'dateTest',
            //   `${
            //     !!contractDate && dateConSubConComparison(val, contractStartEnd, 'less')
            //       ? `Must be later than Contract Awarded Date (${new Date(contractStartEnd?.conStart).toLocaleDateString()})`
            //       :
            //       dateConSubConComparison(val, contractStartEnd, 'more')

            //       ? `Must be before than Contract Awarded Date (${new Date(contractStartEnd?.conEnd).toLocaleDateString()})`
            //       : 'Please select Contract first'
            //   }`,
            //   // return !!val && new Date(val) >= new Date(contractDate);
            //   val => {
            //     console.log(
            //       val,
            //       'valll',
            //       contractDate,
            //       'contract date',
            //       contractStartEnd,
            //     );
            //     if (contractStartEnd) {
            //       return (
            //         !!val &&
            //         dateConSubConComparison(val, contractStartEnd, 'between')
            //       );
            //     }
            //   },
            // )
            .test(
              'name validation fn',
              'Please select Contract first',
              function(value) {
                if (
                  !!value &&
                  contractStartEnd?.conStart &&
                  contractStartEnd?.conEnd
                ) {
                  const customError = dateConSubConComparison(
                    value,
                    contractStartEnd,
                  );

                  return customError
                    ? this.createError({ message: customError })
                    : true;
                } else {
                  return true;
                }
              },
            )
        : null,
    title: CommonYupValidation.requireField(SystemMsgs.title()),
    contractSum:
      conSubcon == 'contract'
        ? CommonYupValidation.requireField(SystemMsgs.docAmt())
        : null,
    subcontractSum:
      conSubcon == 'subcontract' && !wbsAccess
        ? yup
            // .number()
            // .required('Require a proper amount')
            // .max(contractSum, 'Subcontract sum must be less than contract sum')
            // .min(1, 'The amount should not be zero')
            .string()
            .required('Require a proper amount')
            .test('sumZero', 'The amount should not be zero', val => {
              return parseFloat(amtNumStr(val)) > 0;
            })
            .test(
              'sumCont',
              'Subcontract sum must be less than contract sum',
              val => {
                return parseFloat(amtNumStr(val)) <= contractSum;
              },
            )
        : null,
    costCategoryID:
      conSubcon == 'subcontract' && !wbsAccess
        ? CommonYupValidation.requireField(SystemMsgs.tradeSpec())
        : null,
  };
};

const yupRetention = {
  progressRetentionPerc: yup
    .string()
    .required(SystemMsgs.progressRetentionPerc())
    .test('proRetPerc', 'Must not exceed 100%', val => {
      let progRet = parseFloat(amtNumStr(val));
      return new BigNumber(progRet).lte(100);
    }),
  maxRetentionPerc: yup
    .string()
    .required(SystemMsgs.maxRetentionPerc())
    .test('maxRetPerc', 'Must not exceed 100%', val => {
      let maxRet = parseFloat(amtNumStr(val));
      return new BigNumber(maxRet).lte(100);
    }),
};

const yupDocNo = {
  claimPrefix: yup
    .string()
    .test(
      'claimLen',
      'Exceeded maximum 6 characters',
      val => !val || val?.length <= 6,
    ),
  claimRunNum: yup
    .string()
    .test('claimRunLen', 'Exceeded maximum 4 characters', val => {
      let test = amtNumStr(val);
      return !test || test?.toString()?.length <= 4;
    }),
  voPrefix: yup
    .string()
    .test(
      'voLen',
      'Exceeded maximum 6 characters',
      val => !val || val?.length <= 6,
    ),
  voRunNum: yup
    .string()
    .test('voRunLen', 'Exceeded maximum 4 characters', val => {
      let test = amtNumStr(val);
      return !test || test?.toString()?.length <= 4;
    }),
};

export const contactYupValidation = () => {
  return yup.object().shape({
    name: CommonYupValidation.requireField(SystemMsgs.name()),
    contactNo: CommonYupValidation.requireField(SystemMsgs.contactNo()),
    designation: CommonYupValidation.requireField(SystemMsgs.designation()),
    email: CommonYupValidation.validEmail(), // use CommonYupValidation.email() if the email cannot be empty
  });
};

export const subconTradeYupValidation = () => {
  return yup.object().shape({
    wbsID: CommonYupValidation.requireField(SystemMsgs.wbsSelection()),
    costCategoryID: CommonYupValidation.requireField(SystemMsgs.tradeSpec()),
    taxSchemeID: CommonYupValidation.requireField(SystemMsgs.taxType()),
    // creditTerm: CommonYupValidation.requireField(SystemMsgs.creditTerm()),
    tradeAmt: CommonYupValidation.requireField(SystemMsgs.tradeAmt()),
  });
};

export const addressYupValidation = () => {
  return yup.object().shape({
    siteName: CommonYupValidation.requireField(SystemMsgs.siteName()),
    address: CommonYupValidation.requireField(SystemMsgs.contactNo()),
    country: CommonYupValidation.requireField('Country is required'),
  });
};

export const yupObjectFinal = (
  mode: 'add' | 'edit' | 'resubmit',
  currEditType: string,
  conSubcon: 'contract' | 'subcontract',
  dateData: { startDate: string; endDate: string; projDate: string },
  contractSum?: number,
  contractDate?: any,
  contractStartEnd?: any,
  wbsAccess?: boolean,
) => {
  // console.log(currEditType, 'currEditType');
  // console.log(mode, 'YUPMODE');

  if (mode === 'add') {
    return yup.object().shape({
      ...defaultYupValidation(
        conSubcon,
        dateData,
        conSubcon === 'subcontract' ? contractSum : null,
        conSubcon === 'subcontract' ? contractDate : null,
        conSubcon === 'subcontract' ? contractStartEnd : null,
        wbsAccess,
      ),
      ...yupDocNo,
      ...yupRetention,
      contacts: yup
        .array()
        .of(contactYupValidation())
        .required(),
      address: yup
        .array()
        .of(addressYupValidation())
        .required(),
      subconTrade: wbsAccess
        ? yup
            .array()
            .of(subconTradeYupValidation())
            .required()
        : null,
      submissionComment: wbsAccess
        ? CommonYupValidation.requireField(SystemMsgs.comments())
        : null,
    });
  } else if (mode === 'edit' || mode === 'resubmit') {
    if (currEditType === 'info') {
      return yup.object().shape({
        ...defaultYupValidation(
          conSubcon,
          dateData,
          conSubcon === 'subcontract' ? contractSum : null,
          conSubcon === 'subcontract' ? contractDate : null,
          conSubcon === 'subcontract' ? contractStartEnd : null,
          wbsAccess,
        ),
      });
    } else if (currEditType === 'docNo') {
      return yup.object().shape({
        ...yupDocNo,
      });
    } else if (currEditType === 'retention') {
      return yup.object().shape({
        ...yupRetention,
      });
    } else if (currEditType === 'contact') {
      return yup.object().shape({
        contacts: yup
          .array()
          .of(contactYupValidation())
          .required(),
      });
    } else if (currEditType === 'address') {
      return yup.object().shape({
        address: yup
          .array()
          .of(addressYupValidation())
          .required(),
      });
    } else if (currEditType === 'subconTradeExceed') {
      return yup.object().shape({
        submissionComment: CommonYupValidation.requireField(
          SystemMsgs.comments(),
        ),
      });
    } else if (currEditType === 'subconTrade') {
      return yup.object().shape({
        subconTrade: wbsAccess
          ? yup
              .array()
              .of(subconTradeYupValidation())
              .required()
          : null,
      });
    }
  }
};

export const yupCondition = (
  mode: 'add' | 'edit' | 'resubmit',
  currEditType: 'docNo' | 'info',
) => {
  return (
    mode === 'add' ||
    ((mode === 'resubmit' || mode === 'edit') &&
      (currEditType === 'docNo' ||
        currEditType === 'info' ||
        currEditType === 'retention' ||
        currEditType === 'contact' ||
        currEditType === 'address' ||
        currEditType === 'subconTrade'))
  );
};
